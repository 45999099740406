// @flow

import React, { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import "normalize.css";

import { Toast, useToast } from "@unifize/sarah";
import { Global } from "@emotion/react";
import FusionCharts from "fusioncharts";

import SecondaryModals from "./SecondaryModals";
import Home from "src/components/Home";
import SRW from "src/components/SRW";
import GlobalStyle from "src/styles/body";
import * as atypes from "src/constants/actionTypes";
import { Container } from "src/styles";
import NavBar from "./filter/NavBar/Index";
import { getLocationType } from "src/reducers";
import type { State } from "src/types";

const Auth = lazy(() => import("src/components/auth"));
const MobileSSOSignin = lazy(
  () => import("src/components/auth/MobileSSOSignin")
);
const SignUp = lazy(() => import("src/components/signup"));
const Join = lazy(() => import("src/components/join"));
const MobileWebRedirect = lazy(
  () => import("src/components/MobileWebRedirect")
);
const OfflineScreen = lazy(() => import("src/components/OfflineScreen"));
const InfoModal = lazy(() => import("src/components/InfoModal"));

import Workflow from "src/components/Manage/Workflow";
import MangeFile from "src/containers/file/Manage";
import People from "src/containers/user/Contacts/People";
import Groups from "src/components/user/Contacts/Groups";
import UserAnalytics from "src/components/user/Analytics";
import Reports from "src/components/Manage/Reports";
import Forms from "src/components/Manage/Forms";
import Settings from "src/components/Settings";
import Dashboard from "src/components/Dashboard";
import OrgSettings from "src/components/OrgSettings";
import Homescreen from "./Homescreen";
import ProcessBuilder from "src/components/Manage/Builder";
import OAuthAppPermissionPage from "./OAuthAppPermissionPage";
import OrgClonePage from "./OrgClonePage";
import ComparisonChart from "src/components/Manage/Reports/Instances/Chart/New/ComparisonChart";
import ComparisonBuilder from "src/components/Manage/Reports/Instances/Chart/New/ComparisonChart/Builder";

type Props = {
  actionType: string,
  infoModal: {
    isOpen: boolean,
    message: string,
    confirmAction: Function,
    type: string
  }
};

// Initialize FusionCharts with license key
FusionCharts.options.license({
  key: process.env.FUSIONCHARTS_LICENSE_KEY,
  creditLabel: false
});

const getComponent = (actionType: string) => {
  switch (actionType) {
    case atypes.SIGN_IN:
      return (
        <Suspense fallback={<></>}>
          <Auth />
        </Suspense>
      );
    case atypes.MOBILE_SSO_SIGNIN:
      return (
        <Suspense fallback={<></>}>
          <MobileSSOSignin />
        </Suspense>
      );
    case atypes.SIGN_UP:
      return (
        <Suspense fallback={<></>}>
          <SignUp />
        </Suspense>
      );
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
      return <Home />;
    case atypes.JOIN:
      return (
        <Suspense fallback={<></>}>
          <Join />
        </Suspense>
      );
    case atypes.REDIRECT_TO_MOBILE_APP:
      return (
        <Suspense fallback={<></>}>
          <MobileWebRedirect />
        </Suspense>
      );
    case atypes.NO_NETWORK:
      return (
        <Suspense fallback={<></>}>
          <OfflineScreen />
        </Suspense>
      );
    case atypes.HOME:
      return <Home />;
    case atypes.SINGLE_RESPONSE_WINDOW:
      return <SRW />;
    case atypes.SET_PROCESS_REQUEST:
      return (
        <Container>
          <NavBar />
          <Workflow />
        </Container>
      );
    case atypes.EDIT_PROCESS_REQUEST:
      return (
        <Container>
          <NavBar />
          <ProcessBuilder />
        </Container>
      );
    case atypes.CREATE_PROCESS_REQUEST:
      return (
        <Container>
          <NavBar />
          <ProcessBuilder />
        </Container>
      );
    case atypes.SET_CONTACTS_REQUEST:
      return (
        <Container>
          <NavBar />
          <People />
        </Container>
      );
    case atypes.SET_GROUPS_REQUEST:
      return (
        <Container>
          <NavBar />
          <Groups />
        </Container>
      );
    case atypes.SET_FILE_REQUEST:
      return (
        <Container>
          <NavBar />
          <MangeFile />
        </Container>
      );
    case atypes.SET_USER_ANALYTICS_REQUEST:
      return (
        <Container>
          <NavBar />
          <UserAnalytics />
        </Container>
      );
    case atypes.SET_REPORTS_REQUEST:
      return (
        <Container>
          <NavBar />
          <Reports />
        </Container>
      );
    case atypes.SET_FORMS_REQUEST:
      return (
        <Container>
          <NavBar />
          <Forms />
        </Container>
      );
    case atypes.SET_SETTINGS_REQUEST:
      return (
        <Container>
          <NavBar />
          <Settings />
        </Container>
      );
    case atypes.SET_DASHBOARD_REQUEST:
      return (
        <Container>
          <NavBar />
          <Dashboard />
        </Container>
      );
    case atypes.SET_ORG_SETTINGS_REQUEST:
      return (
        <Container>
          <NavBar />
          <OrgSettings />
        </Container>
      );
    case atypes.SET_HOME_SCREEN_REQUEST:
      return (
        <Container>
          <NavBar />
          <Homescreen />
        </Container>
      );
    case atypes.AUTHORIZE_OAUTH_APP_PAGE:
      return <OAuthAppPermissionPage />;
    case atypes.ORG_CLONE_PAGE:
      return <OrgClonePage />;
    case atypes.SET_CHART_REQUEST:
      return (
        <Container>
          <NavBar />
          <ComparisonChart />
        </Container>
      );
    case atypes.SELECTED_CHART_REQUEST:
    case atypes.EDIT_CHART:
      return (
        <Container>
          <NavBar />
          <ComparisonBuilder />
        </Container>
      );

    default:
      return <Home />;
  }
};

const App = ({ actionType, infoModal }: Props) => {
  const toast = useToast();
  useEffect(() => {
    // Update the online status icon based on connectivity
    window.addEventListener("online", () => {
      toast.dismiss();
      toast.success("You are back online");
    });

    window.addEventListener("offline", () => {
      toast.error("You are offline");
    });
  }, []);

  return (
    <>
      <Global styles={GlobalStyle} />
      {infoModal ? (
        <Suspense fallback={<></>}>
          <InfoModal />
        </Suspense>
      ) : null}
      {getComponent(actionType)}

      <Toast duration={4000} showIcon={false} />
      <SecondaryModals />
    </>
  );
};

const mapStateToProps = (state: State) => ({
  actionType: getLocationType(state.location),
  infoModal: state.app.modal.infoModal.isOpen
});

export default connect(mapStateToProps)(App);
