// @flow

import React, { useCallback } from "react";
import { toast } from "@unifize/sarah";

import BlueOutlineButton from "src/components/Buttons/BlueOutline";
import Icon from "src/icons";

const Share = () => {
  const handleShare = useCallback(() => {
    const textField = document.createElement("textarea");
    textField.innerText = window.location.href || "";

    if (document.body) {
      document.body.appendChild(textField);
    }
    textField.select();
    document.execCommand("copy");
    textField.remove();

    toast.success(`Copied your current Forms table view`);
  }, []);

  return (
    <BlueOutlineButton
      leftIcon={<Icon type="shareDots" />}
      onClick={handleShare}
    >
      Share
    </BlueOutlineButton>
  );
};

export default Share;
