// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";
import Link from "redux-first-router-link";
import { useClientDimensions } from "react-client-dimensions";

import {
  Info as StyledInfo,
  CloseDock,
  BackContainer,
  NavContainer,
  InfoTitleContainer
} from "./styles";
import Icon from "src/icons";
import {
  getCurrentChecklist,
  getMandatoryFieldCount,
  getTemplateId
} from "src/reducers";
import { componentPermissions } from "src/constants/roleManagement";
import useComponentPermission from "src/hooks/useComponentPermission";
import { showDock, hideDock, showSrwDock, hideSrwDock } from "src/actions/dock";
import * as colors from "src/styles/constants/colors";
import { backendUrl } from "src/config/firebase";
import { toast } from "@unifize/sarah";
import type { AppState, UID } from "src/types";
import * as atypes from "src/constants/actionTypes";
import {
  Text,
  Menu as ChakraMenu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  HStack,
  Box
} from "@chakra-ui/react";
import * as chakraStyles from "./chakraStyles";

type Props = {
  currentUser: UID,
  checklists: any,
  _showDock: Function,
  _hideDock: Function,
  _showSrwDock: Function,
  title: string,
  back?: boolean,
  isSrw?: boolean,
  showClose: boolean,
  _hideSrwDock: Function,
  isSrwMobile: boolean,
  roomId: number,
  refreshOption: boolean,
  navigatedFromHeader: boolean,
  mandatoryFieldCount: number,
  templateId: ?number
};

// SRW = Single Response window
const Info = ({
  checklists,
  _showDock,
  _hideDock,
  _showSrwDock,
  title,
  back,
  isSrw,
  showClose,
  _hideSrwDock,
  isSrwMobile,
  roomId,
  refreshOption,
  navigatedFromHeader,
  mandatoryFieldCount,
  templateId
}: Props) => {
  const { vw } = useClientDimensions();
  const goBack = useCallback(() => {
    if (isSrw) _showSrwDock(null);
    else _showDock("info");
  }, [_showDock, _showSrwDock]);

  const closeDock = useCallback(() => {
    if (showClose || isSrwMobile) _hideSrwDock();
    else if (isSrw && title === "Checklist") _showSrwDock("info");
    else {
      _hideDock();
    }
  }, [isSrw, title, showClose]);

  const downloadJson = async (provider: "adobe" | "pdfgen") => {
    try {
      const id = checklists[0].id;
      const response = await fetch(
        `${backendUrl}/chatroom/${roomId}/checklist/${id}/json/${provider}`
      );

      if (response.ok) {
        toast.success(
          "Generating JSON. Download link will be emailed to you in a couple of minutes."
        );
      } else {
        toast.error("Unable to generate json");
      }
    } catch (error) {
      console.log(error);
      toast.error("Unable to generate json");
    }
  };

  const checklistDownloadAllowed = useComponentPermission(
    componentPermissions.downloadChecklist
  );
  const processSettingsAllowed = useComponentPermission(
    componentPermissions.processSettings
  );

  return (
    <StyledInfo refreshOption={refreshOption}>
      <NavContainer>
        {((back === true && !(isSrw && title === "Data")) ||
          (isSrw && title === "Info")) &&
        !isSrwMobile &&
        !navigatedFromHeader ? (
          <BackContainer onClick={goBack}>
            <Icon type="back" />
          </BackContainer>
        ) : null}
        {vw <= 779 && (
          <InfoTitleContainer>
            <span>{title}</span>
            {title === "Checklist" && mandatoryFieldCount ? (
              <Text variant="redDarkSm">{`${mandatoryFieldCount} mandatory ${
                mandatoryFieldCount === 1 ? "field" : "fields"
              } remaining`}</Text>
            ) : null}
          </InfoTitleContainer>
        )}
      </NavContainer>

      <HStack {...chakraStyles.actions}>
        {title === "Checklist" &&
        templateId &&
        !isSrw &&
        processSettingsAllowed ? (
          <Link
            to={{
              type: atypes.EDIT_PROCESS_REQUEST,
              payload: { id: templateId },
              meta: {
                query: {
                  tab: "Checklist"
                }
              }
            }}
            style={chakraStyles.processBuilderLink}
          >
            <Icon type="gear" variant="outlined" />
          </Link>
        ) : null}

        {title === "Checklist" &&
          checklistDownloadAllowed &&
          !isSrwMobile &&
          !isSrw && (
            <ChakraMenu>
              <MenuButton
                as={IconButton}
                icon={<Icon type="download" />}
                sx={chakraStyles.jsonMenuButton}
                variant="unstyled"
              />
              <Box sx={chakraStyles.jsonMenuList}>
                <MenuList>
                  <MenuItem onClick={() => downloadJson("adobe")}>
                    JSON for Adobe
                  </MenuItem>
                  <MenuItem onClick={() => downloadJson("pdfgen")}>
                    JSON for PDFGeneratorAPI
                  </MenuItem>
                </MenuList>
              </Box>
            </ChakraMenu>
          )}

        {!(isSrw && (title === "Info" || title === "Files")) || isSrwMobile ? (
          <CloseDock onClick={closeDock}>
            <Icon
              type={
                !(isSrw && title === "Checklist") || isSrwMobile
                  ? "close"
                  : "dockMenu"
              }
              cursor="pointer"
              color={colors.primary}
            />
          </CloseDock>
        ) : null}
      </HStack>
    </StyledInfo>
  );
};

Info.defaultProps = {
  back: () => {},
  isSrw: false
};

const mapStateToProps = ({ app }: { app: AppState }, { roomId }: Props) => ({
  navigatedFromHeader: app.header.navigatedFromHeader,
  isSrwMobile: app.srw.isMobile,
  checklists: getCurrentChecklist(app),
  mandatoryFieldCount: getMandatoryFieldCount(app),
  templateId: getTemplateId(app, `${roomId}`)
});

export default connect(mapStateToProps, {
  _showDock: showDock,
  _hideDock: hideDock,
  _showSrwDock: showSrwDock,
  _hideSrwDock: hideSrwDock
})(Info);
