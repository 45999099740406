// @flow

import React, { useCallback } from "react";
import { toast } from "@unifize/sarah";
import { getFileExtension, verifyImage } from "src/utils/file";

import { Picture as StyledPicture, PictureBox } from "./styles";

type Props = {
  image: ?Object,
  photoUrl: ?string,
  handlePicture: Function
};
const Picture = ({ image, photoUrl, handlePicture }: Props) => {
  const handleImage = useCallback(
    (e: any) => {
      const file = e.target.files[0];
      const fileExtension = getFileExtension(file.name);

      if (verifyImage(fileExtension)) {
        handlePicture(file);
      } else {
        toast.error("Only images are allowed.");
      }
    },
    [handlePicture]
  );

  return (
    <PictureBox>
      <StyledPicture>
        <input
          type="file"
          id="groupImage"
          name="groupImage"
          title=""
          accept="image/*"
          onChange={handleImage}
        />

        {photoUrl ? (
          <img src={photoUrl} alt="group logo" />
        ) : image ? (
          <img src={URL.createObjectURL(image)} alt="group logo" />
        ) : (
          <span>Upload image</span>
        )}
      </StyledPicture>
      <span>Edit</span>
    </PictureBox>
  );
};

export default Picture;
