// @flow

import React from "react";
import { useSelector } from "react-redux";
import { toast } from "@unifize/sarah";
import { CopyToClipboard } from "react-copy-to-clipboard";

import FormTextInput from "./Form/FormTextInput";
import Icon from "src/icons";

import { getSSOConfig } from "src/reducers";

import { GeneratedFieldsContainer, FieldContainer, Label } from "./styles";
import * as colors from "src/styles/constants/colors";
import * as styles from "./styles";

import type { SSOConfig } from "src/types";

const GeneratedFields = () => {
  const ssoConfig: SSOConfig = useSelector(({ app }) => getSSOConfig(app));

  return (
    <GeneratedFieldsContainer>
      <FieldContainer>
        <Label htmlFor="generated-entity">Service Provider Entity ID</Label>
        <div
          style={{
            position: "relative"
          }}
        >
          <FormTextInput
            id="generated-entity"
            name="generated-entity"
            readOnly={true}
            value={ssoConfig.serviceProviderEntityId}
          />
          <CopyToClipboard
            text={ssoConfig.serviceProviderEntityId}
            onCopy={() => {
              toast.success("Copied service provider entity ID");
            }}
          >
            <Icon
              type="copy"
              size={24}
              style={styles.copyIcon}
              fill={colors.lightBlue600}
            />
          </CopyToClipboard>
        </div>
      </FieldContainer>
      <FieldContainer>
        <Label htmlFor="generated-callback-url">
          Authorization Callback URL
        </Label>
        <div
          style={{
            position: "relative"
          }}
        >
          <FormTextInput
            id="generated-callback-url"
            name="generated-callback-url"
            readOnly={true}
            value={ssoConfig.callbackUrl}
          />
          <CopyToClipboard
            text={ssoConfig.callbackUrl}
            onCopy={() => {
              toast.success("Copied callback URL");
            }}
          >
            <Icon
              type="copy"
              size={24}
              style={styles.copyIcon}
              fill="#1581BA"
            />
          </CopyToClipboard>
        </div>{" "}
      </FieldContainer>
    </GeneratedFieldsContainer>
  );
};

export default GeneratedFields;
