// @flow

import * as R from "ramda";
import { toast } from "@unifize/sarah";
import { call, put, select, takeEvery, take } from "redux-saga/effects";
import resizeImage from "src/utils/image";
import {
  signup,
  validateSignUpCode,
  setupOrg,
  joinExistingOrg as joinExistingOrgApi,
  sendVerificationEmail as sendSrwSignUpEmail
} from "src/api/signup";

import * as api from "src/api/auth";
import * as userApi from "src/api/user";
import * as actions from "src/actions/signup";
import * as atypes from "src/constants/actionTypes";
import getAppState, {
  getSignUpEmail,
  getUser,
  getCurrentUserId
} from "src/selectors";

import type { Action } from "src/types";

function* sendVerificationMail(): any {
  try {
    const { uid, email } = yield select(getUser);
    const authToken = yield call(api.getAuthToken);
    sessionStorage.setItem("authToken", authToken);
    yield call(api.sendAuthToken, authToken);
    yield put(actions.setEmail(email));
    console.log("Authenticated with API");
    // Call the API to send the verification email
    yield call(signup, uid);
    console.log("Verification email sent");
    yield put({
      type: atypes.SIGN_UP,
      payload: {
        page: "send-success"
      }
    });
  } catch (error) {
    // User is created in the firebase but fails to be created in backend
    console.log(
      "Error authenticating with the API or sending verification email",
      error
    );
    yield put({
      type: atypes.SEND_VERIFICATION_MAIL_FAILURE,
      payload: { error }
    });
  }
}

function* watchVerifyEmail(): any {
  yield takeEvery(atypes.SEND_VERIFICATION_MAIL_REQUEST, sendVerificationMail);
}

function* updatePassword({ payload }: Action): any {
  try {
    const { password } = payload;
    yield call(userApi.updatePassword, password);
    yield put({ type: atypes.UPDATE_PASSWORD_SUCCESS });
  } catch (error) {
    const { message } = error;
    yield put({
      type: atypes.UPDATE_PASSWORD_FAILURE,
      payload: { message }
    });
  }
}

function* watchUpdatePassword(): any {
  yield takeEvery(atypes.UPDATE_PASSWORD_REQUEST, updatePassword);
}

function* uploadProfilePicture({ payload }: Action): any {
  try {
    const { file } = payload;
    if (file) {
      const resizedImage = yield resizeImage(file);
      const url = yield call(userApi.profilePhotoFile, resizedImage);
      yield call(userApi.updateProfilePicture, url);

      const uid = yield select(getCurrentUserId);
      yield put({
        type: atypes.UPLOAD_PROFILE_PICTURE_SUCCESS,
        payload: {
          uid,
          photoUrl: url
        }
      });
    }
  } catch (error) {
    yield put({
      type: atypes.UPLOAD_PROFILE_PICTURE_FAILURE,
      payload: { error }
    });
  }
}

function* watchUploadProfilePicture(): any {
  yield takeEvery(atypes.UPLOAD_PROFILE_PICTURE_REQUEST, uploadProfilePicture);
}

function* updateDisplayName({ payload }: Action): any {
  try {
    const { displayName } = payload;
    const trimmedDisplayName = R.trim(displayName);
    yield call(userApi.updateDisplayName, trimmedDisplayName);
    yield put({
      type: atypes.UPDATE_DISPLAY_NAME_SUCCESS,
      payload: {
        displayName: trimmedDisplayName
      }
    });
  } catch (error) {
    const { message } = error;
    yield put({
      type: atypes.UPDATE_DISPLAY_NAME_FAILURE,
      payload: { message }
    });
  }
}

function* watchUpdateDisplayName(): any {
  yield takeEvery(atypes.UPDATE_DISPLAY_NAME_REQUEST, updateDisplayName);
}

function* createUser(action: Action): any {
  yield put(actions.clearAllErrors());
  try {
    const email = yield select(getSignUpEmail);
    const { uid } = yield select(getUser);
    const { displayName, password, profilePicture } = action.payload;
    if (!uid) {
      yield call(api.createUser, email, password);
    } else {
      yield call(userApi.updatePassword, password);
    }
    yield put({
      type: atypes.UPDATE_DISPLAY_NAME_REQUEST,
      payload: { displayName }
    });
    yield put({
      type: atypes.UPLOAD_PROFILE_PICTURE_REQUEST,
      payload: { file: profilePicture }
    });
    // Wait for UPDATE_DISPLAY_NAME_SUCCESS then
    // send auth code
    yield take(atypes.UPDATE_DISPLAY_NAME_SUCCESS);
    yield put({ type: atypes.SIGN_UP, payload: { page: "send-authcode" } });
  } catch (error) {
    // User is not created in firebase
    yield put({
      type: atypes.CREATE_USER_FAILURE,
      payload: error
    });
  }
}

function* watchCreateUser(): any {
  yield takeEvery(atypes.CREATE_USER_REQUEST, createUser);
}

function* checkEmailValidity(action: Action): any {
  yield put(actions.clearAllErrors());
  const { email } = action.payload;
  try {
    const existingUser = yield call(api.isExistingUser, email);
    if (existingUser) {
      throw new Error("User already exists");
    }
    yield put(actions.setEmail(email));
    yield put(actions.setupProfile());
  } catch (error) {
    yield put(actions.emailValidationFailed(error));
  }
}

function* watchCheckSignUpMail(): any {
  yield takeEvery(atypes.CHECK_SIGN_UP_EMAIL, checkEmailValidity);
}

function* takeToTeamSetup(action: Action): any {
  try {
    const { token } = action.payload;
    yield put({ type: atypes.EMAIL_VERIFIED });
    yield call(api.signInWithCustomAuthToken, token);
    const authToken = yield call(api.getAuthToken);
    sessionStorage.setItem("authToken", authToken);
    yield call(api.sendAuthToken, authToken);
    yield put({ type: atypes.SIGN_UP, payload: { page: "team" } });
  } catch (error) {
    console.log(error);
  }
}

function* watchValidateCodeSuccess(): any {
  yield takeEvery(atypes.VALIDATE_SIGN_UP_CODE_SUCCESS, takeToTeamSetup);
}

function* validateCode({ payload }: Action): any {
  const { authCode, email } = payload;
  yield put(actions.setEmail(email));
  try {
    const { token, orgs } = yield call(validateSignUpCode, { authCode, email });
    yield put({
      type: atypes.SET_AVAILABLE_ORGS,
      payload: { orgs }
    });
    yield put({
      type: atypes.VALIDATE_SIGN_UP_CODE_SUCCESS,
      payload: { token }
    });
  } catch (error) {
    yield put({
      type: atypes.VALIDATE_SIGN_UP_CODE_FAILURE,
      payload: { error }
    });
  }
}

function* watchValidateCode(): any {
  yield takeEvery(atypes.VALIDATE_SIGN_UP_CODE_REQUEST, validateCode);
}

function* reauthenticateAndSignIn(action: Action): any {
  const email = yield select(getSignUpEmail);

  // Check if user is in signup journey
  if (email) {
    try {
      const { orgId } = action.payload;
      const authToken = yield call(api.getAuthToken);
      sessionStorage.setItem("authToken", authToken);
      // Reauthenticate
      yield call(api.sendAuthToken, authToken, orgId);
      console.log("Reauthentication with Org Success");
      yield put({ type: atypes.CLEAR_SIGNUP_DETAILS });
    } catch (error) {
      // TODO: Handle errors
      console.log("Error reauthentication using org ID:", error);
    }
  }
}

function* watchSetupOrgSuccess(): any {
  yield takeEvery(atypes.SETUP_ORG_SUCCESS, reauthenticateAndSignIn);
}

function* settingUpOrg(action: Action): any {
  yield put(actions.clearAllErrors());
  const { title, member1, member2, member3, domain, autoJoin } = action.payload;
  const invitees = [member1, member2, member3].filter(val => !!val);
  try {
    const response = yield call(setupOrg, {
      title,
      domain,
      autoJoin,
      invitees
    });
    const orgId = R.prop("id", response);
    if (!orgId) {
      throw new Error("Org Creation Failed");
    }
    yield put({ type: atypes.SETUP_ORG_SUCCESS, payload: { orgId } });
  } catch (error) {
    yield put(actions.setupOrgFailed(error));
    if (error.status === 409) {
      toast.error("org already exists");
    } else {
      toast.error("Unable to create new org");
    }
  }
}

function* watchSetupOrg(): any {
  yield takeEvery(atypes.SETUP_ORG_REQUEST, settingUpOrg);
}

function* joinExistingOrg(action: Action) {
  try {
    const { payload } = action;
    yield call(joinExistingOrgApi, payload.orgId);

    const authToken = yield call(api.getAuthToken);
    sessionStorage.setItem("authToken", authToken);
    yield call(api.sendAuthToken, authToken, payload.orgId);

    yield put({ type: atypes.CLEAR_SIGNUP_DETAILS });
  } catch (e) {
    yield put({
      type: atypes.JOIN_EXISTING_ORG_FAILURE,
      payload: {
        error: "Error joining org"
      }
    });
  }
}

function* watchJoinExistingOrg(): any {
  yield takeEvery(atypes.JOIN_EXISTING_ORG_REQUEST, joinExistingOrg);
}

function* srwSignUp() {
  try {
    const { email, orgId } = (yield select(getAppState)).srw.query;
    const response = yield call(sendSrwSignUpEmail, email, orgId);
    yield put({
      type: atypes.SRW_SIGN_UP_SUCCESS,
      payload: { response }
    });
  } catch (error) {
    yield put({
      type: atypes.SRW_SIGN_UP_FAILURE,
      payload: { error }
    });
  }
}

function* watchSRWSignUp(): any {
  yield takeEvery(atypes.SRW_SIGN_UP, srwSignUp);
}

export default [
  watchCreateUser(),
  watchCheckSignUpMail(),
  watchUploadProfilePicture(),
  watchVerifyEmail(),
  watchValidateCode(),
  watchValidateCodeSuccess(),
  watchSetupOrg(),
  watchSetupOrgSuccess(),
  watchUpdateDisplayName(),
  watchUpdatePassword(),
  watchJoinExistingOrg(),
  watchSRWSignUp()
];
