// @flow

import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "@unifize/sarah";

import { UrlCopy as StyledUrlCopy, UrlIconWrapper } from "./styles";
import Tooltip from "src/components/Tooltip";
import Icon from "src/icons";
import type { RoomId } from "src/types";

type Props = {
  roomId: RoomId
};

const UrlCopy = ({ roomId }: Props) => {
  const [copied: boolean, copy: Function] = useState(false);

  useEffect(() => {
    copy(false);
  }, [roomId]);

  const handleCopy = () => {
    copy(true);
    toast.success(`Copied conversation address`);
    setTimeout(() => {
      copy(false);
    }, 800);
  };

  return (
    <Tooltip title="Copy conversation link" align="right">
      <StyledUrlCopy copied={copied}>
        <CopyToClipboard
          text={window.location.href || ""}
          onCopy={() => handleCopy()}
        >
          <UrlIconWrapper type="button">
            <Icon type="urlCopy" />
          </UrlIconWrapper>
        </CopyToClipboard>
      </StyledUrlCopy>
    </Tooltip>
  );
};

export default UrlCopy;
