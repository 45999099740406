// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import localForage from "localforage";

import { ProfileMenu as StyledProfileMenu, MenuItem } from "../styles";
import Update from "./Update";
import { SubHeading } from "./styles";
import ViewAs from "./ViewAs";
import OrgMenu from "./OrgMenu";
import { HiddenInput } from "src/styles";
import { logout, setProfileImage } from "src/actions";
import { nativigateToSettings } from "src/actions/settings";
import {
  toggleProfileModal,
  toggleChangePasswordModal
} from "src/actions/modal";
import { setOrgSettings } from "src/actions/orgSettings";
import { toggleCreateOrgModal, toggleCloneOrgModal } from "src/actions/modal";
import NameOrEmail from "src/components/user/NameOrEmailText";
import Icon from "src/icons";
import { showView } from "src/actions";
import {
  getAllOrgs,
  getUserRole,
  getOrgName,
  getCurrentUserId,
  isCurrentUserUnifizeEmployee,
  getCurrentOrg,
  getLastOrg,
  getAuthProvider
} from "src/reducers";
import type { Set as ImmutableSet } from "immutable";
import { toast } from "@unifize/sarah";
import { getFileExtension, verifyImage } from "src/utils/file";
import useComponentPermission from "src/hooks/useComponentPermission";
import { componentPermissions } from "src/constants/roleManagement";
import useClickoutside from "src/hooks/useClickoutside";

import type { AppState, UID } from "src/types";

type Props = {
  uid: UID,
  orgTitle: string,
  orgs: ImmutableSet<number>,
  role: string,
  orgId: number,
  authProvider: string | null,
  _setProfileImage: Function,
  outerRef: HTMLDivElement,
  handleClose: Function,
  _logout: Function,
  _toggleProfileModal: Function,
  _nativigateToSettings: Function,
  _setOrgSettings: Function,
  _toggleCreateOrgModal: Function,
  _toggleCloneOrgModal: Function,
  _showView: Function,
  _toggleChangePasswordModal: Function,
  canCloneOrg: boolean
};

const ProfileMenu = ({
  uid,
  orgTitle,
  _setProfileImage,
  outerRef,
  handleClose,
  authProvider,
  _logout,
  orgs,
  role,
  orgId,
  _toggleProfileModal,
  _nativigateToSettings,
  _setOrgSettings,
  _toggleCreateOrgModal,
  _toggleCloneOrgModal,
  _showView,
  _toggleChangePasswordModal,
  canCloneOrg
}: Props) => {
  const handleFileChange = useCallback(
    (event: any) => {
      const file = event.target.files[0];
      const fileExtension = getFileExtension(file.name);

      if (verifyImage(fileExtension)) {
        _setProfileImage(file);
      } else {
        toast.error("Only images are allowed.");
      }

      handleClose();
    },
    [_setProfileImage, handleClose]
  );

  const handleLogout = useCallback(
    (event: any) => {
      event.stopPropagation();
      _logout();
    },
    [logout]
  );

  useClickoutside({ outerRef, handleClose });

  const openModal = (modalFunction: Function) => {
    modalFunction({ open: true });
  };

  const handleSettings = useCallback(() => {
    _nativigateToSettings("notification");
    _showView("orgSettings");
  }, [_nativigateToSettings, _showView]);

  const handleRefresh = async () => {
    try {
      const orgId = sessionStorage.getItem("lastOrg");

      if (orgId) {
        // Clearing indexdb
        await localForage.setItem(`chatrooms-${orgId}`, JSON.stringify([]));
        await localForage.setItem(`lastFetched-${orgId}`, "");
      }
    } catch (error) {
      console.log("unable to clear database", error);
    }
    window.location.reload();
  };

  const handleOrgSettings = useCallback(() => {
    _showView("orgSettings");
    _setOrgSettings("home-screen");
  }, [_showView, _setOrgSettings]);

  const showChangeOrg = orgs.count() > 1;

  const showViewAs = role === "phantom";

  const orgSettingsEnabled = useComponentPermission(
    componentPermissions.homeSettings
  );

  return (
    <StyledProfileMenu>
      <Update />
      <SubHeading>
        <NameOrEmail uid={uid} />
      </SubHeading>
      <ul>
        <MenuItem onClick={() => openModal(_toggleProfileModal)}>
          <Icon type="profile" />
          <span>Update Name</span>
        </MenuItem>
        <MenuItem onClick={e => e.stopPropagation()}>
          <HiddenInput>
            <input
              type="file"
              id="profilePhoto"
              onChange={handleFileChange}
              accept="image/*"
              title=""
            />
          </HiddenInput>
          <Icon type="profileCircle" />
          <span>Change Profile Picture</span>
        </MenuItem>
        <MenuItem onClick={handleSettings}>
          <Icon type="notification" />
          <span>Notification Settings</span>
        </MenuItem>
        <MenuItem onClick={handleRefresh}>
          <Icon type="refresh2" />
          <span>Refresh</span>
        </MenuItem>
        {(!authProvider || authProvider === "password") && (
          <MenuItem onClick={() => openModal(_toggleChangePasswordModal)}>
            <Icon type="security" />
            <span>Change password</span>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <Icon type="logOut" />
          <span>Logout</span>
        </MenuItem>
      </ul>
      <SubHeading>{orgTitle}</SubHeading>
      <ul>
        {orgSettingsEnabled && (
          <MenuItem onClick={handleOrgSettings}>
            <Icon type="settings" />
            <span>Org settings</span>
          </MenuItem>
        )}

        {/* Show org creation option only for Unifize Org */}
        {orgId === 1 && (
          <MenuItem onClick={() => _toggleCreateOrgModal({ open: true })}>
            <Icon type="org" />
            <span>Create New Org</span>
          </MenuItem>
        )}

        {canCloneOrg && (
          <MenuItem onClick={() => _toggleCloneOrgModal({ open: true })}>
            <Icon type="copy" />
            <span>Clone Org</span>
          </MenuItem>
        )}

        {showChangeOrg && <OrgMenu />}

        {showViewAs && <ViewAs />}
      </ul>
    </StyledProfileMenu>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  uid: getCurrentUserId(app),
  orgTitle: getOrgName(app, parseInt(app.orgs.last, 10)),
  orgs: getAllOrgs(app),
  role: getUserRole(app),
  orgId: getLastOrg(app),
  canCloneOrg:
    isCurrentUserUnifizeEmployee(app) && getCurrentOrg(app).cloneable,
  authProvider: getAuthProvider(app)
});

export default connect(mapStateToProps, {
  _toggleProfileModal: toggleProfileModal,
  _setProfileImage: setProfileImage,
  _logout: logout,
  _nativigateToSettings: nativigateToSettings,
  _setOrgSettings: setOrgSettings,
  _toggleCreateOrgModal: toggleCreateOrgModal,
  _toggleCloneOrgModal: toggleCloneOrgModal,
  _showView: showView,
  _toggleChangePasswordModal: toggleChangePasswordModal
})(ProfileMenu);
