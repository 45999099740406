// @flow

import * as R from "ramda";
import { toast } from "@unifize/sarah";
import {
  race,
  delay,
  put,
  takeEvery,
  takeLatest,
  take,
  select,
  throttle,
  call
} from "redux-saga/effects";
import { rsf } from "src/db";

import * as api from "src/api/status";
import * as atypes from "src/constants/actionTypes";
import getAppState, { getLastOrg } from "src/selectors";

import type { Action, WorkflowStatuses } from "src/types";

import { showAlert } from "src/actions/alert";
import { alerts as alertIds } from "src/constants";

import { getWorkflowBuilderStatus } from "src/reducers";
import {
  setWorkflowBuiderAttributes,
  showStatusRemoveWarning,
  fetchStatusCount
} from "src/actions/workflows";

function* statusSync(): any {
  try {
    yield delay(2000);
    const orgId = yield select(getLastOrg);
    const channel = rsf.firestore.channel(`orgs/${orgId}/statuses`);

    while (true) {
      const snapshot = yield take(channel);
      const statuses = {};

      for (const { doc } of snapshot.docChanges()) {
        statuses[doc.id] = { ...doc.data(), id: parseInt(doc.id, 10) };
      }

      yield put({
        type: atypes.SYNC_STATUSES_SUCCESS,
        payload: {
          statuses
        }
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SYNC_STATUSES_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchStatusSync(): any {
  yield takeEvery(atypes.API_AUTH_SUCCESS, statusSync);
}

function* watchSrwStatusSync(): any {
  yield takeLatest(atypes.SRW_SERVER_AUTH_SUCCESS, statusSync);
}

function* searchStatus({ payload }: Action): any {
  try {
    const text = R.toLower(payload.searchString || "");

    const { pendingStatus, completedStatus } = (yield select(getAppState))
      .workflow.builderDialog;

    let statuses = [
      {
        title: "PENDING",
        id: -1
      },
      {
        title: "COMPLETED",
        id: -2
      },
      ...R.values((yield select(getAppState)).statuses.byId.toJS())
    ];

    const existingStatus = R.map(R.prop("id"), [
      ...pendingStatus,
      ...completedStatus
    ]);

    const sortByTitle = R.sortBy(R.prop("title"));

    const result = R.map(
      x => parseInt(x.id, 10),
      sortByTitle(
        R.filter(
          s =>
            R.includes(text, R.toLower(s.title || "")) &&
            !R.includes(parseInt(s.id, 10), existingStatus),
          statuses
        )
      )
    );

    yield put({
      type: atypes.SEARCH_STATUS_SUCCESS,
      payload: {
        result
      }
    });
  } catch (error) {
    yield put({
      type: atypes.SEARCH_STATUS_FAILURE,
      payload: { error }
    });
  }
}

function* watchSeachStatus(): any {
  yield throttle(200, atypes.SEARCH_STATUS_REQUEST, searchStatus);
}

function* createStatus({ payload }: Action): any {
  try {
    const newStatus = yield call(api.createStatus, payload.title);

    yield put({
      type: atypes.CREATE_STATUS_SUCCESS,
      payload: {}
    });

    const { status } = (yield select(getAppState)).workflow.builderDialog;

    yield put({
      type: atypes.SET_WORKFLOW_BUILDER_ATTRIBUTES,
      payload: {
        value: {
          status: [
            ...(status || []),
            {
              id: newStatus.id,
              active: payload.active,
              locked: payload.locked
            }
          ]
        }
      }
    });

    toast.success(`Status created successfully`);
  } catch (error) {
    yield put({
      type: atypes.CREATE_STATUS_FAILURE,
      payload: { error }
    });
    toast.error(`Unable to create status`);
  }
}

function* watchCreateStatus(): any {
  yield takeEvery(atypes.CREATE_STATUS_REQUEST, createStatus);
}

/**
 * Check if the status to be deleted is the only pending/completed status
 * @param  {string} id
 * @param  {WorkflowStatuses} statuses
 * @returns boolean
 */
const canDeleteStatus = (id: string, statuses: WorkflowStatuses): boolean => {
  const pendingAndComplete = R.map(R.prop("active"), statuses || []);

  const allStatuses = R.groupBy(n => (n ? "completed" : "pending"));

  const { completed, pending } = allStatuses(pendingAndComplete);

  const statusToBeDeleted = n => n.id === id;
  const { active } = R.filter(statusToBeDeleted, statuses)[0];

  if (active) {
    return completed?.length === 1;
  }

  return pending?.length === 1;
};

function* deleteStatus({ payload }) {
  const { templateId, id } = payload;

  let appState = yield select(getAppState);
  const statuses = getWorkflowBuilderStatus(appState);

  if (canDeleteStatus(id, statuses)) {
    yield put(
      showAlert({
        id: alertIds.invalidStatusesInProcessTemplate
      })
    );
  } else {
    // Fetch statusCount to ensure data is upto date
    yield put(fetchStatusCount(templateId));
    yield race([
      take(atypes.FETCH_STATUS_COUNT_SUCCESS),
      take(atypes.FETCH_STATUS_COUNT_FAILURE)
    ]);

    appState = yield select(getAppState);
    const statusCount = appState.workflow.statusCount;

    if (statusCount[id] && statusCount[id] > 0) {
      yield put(
        showStatusRemoveWarning({
          count: statusCount[id],
          id
        })
      );
    } else {
      try {
        yield call(api.deleteStatus, templateId, id);

        yield put({
          type: atypes.DELETE_STATUS_SUCCESS,
          payload: {}
        });
      } catch (error) {
        yield put({
          type: atypes.DELETE_STATUS_FAILURE,
          payload: { error }
        });
        toast.error(`Unable to delete status`);
      }

      yield put(
        setWorkflowBuiderAttributes({
          status: R.filter(s => s.id !== id, statuses)
        })
      );
    }
  }
}

function* watchDeleteStatus(): any {
  yield takeEvery(atypes.DELETE_STATUS_REQUEST, deleteStatus);
}

function* editStatus({ payload }: Action): any {
  try {
    yield call(api.editStatus, payload.statusId, payload.name);

    yield put({
      type: atypes.EDIT_STATUS_SUCCESS,
      payload: {}
    });
  } catch (error) {
    yield put({
      type: atypes.EDIT_STATUS_FAILURE,
      payload: { error }
    });
    toast.error(`Unable to edit status`);
  }
}

function* watchEditStatus(): any {
  yield takeEvery(atypes.EDIT_STATUS_REQUEST, editStatus);
}

function* workflowStatus({ payload }: Action): any {
  try {
    let status = {};

    payload.workflows.forEach(w => {
      status[w.id] = R.mergeAll(
        R.map(
          s => ({
            [s.id]: { active: s.active, archiveMode: s.settings?.archiveMode }
          }),
          w.status || []
        )
      );
    });

    yield put({
      type: atypes.SYNC_WORKFLOWS_STATUS_SUCCESS,
      payload: {
        status
      }
    });
  } catch (error) {
    console.log(error);
  }
}

function* watchWorkflowStatus(): any {
  yield takeEvery(atypes.SYNC_WORKFLOWS_SUCCESS, workflowStatus);
}

export default [
  watchStatusSync(),
  watchSeachStatus(),
  watchCreateStatus(),
  watchWorkflowStatus(),
  watchSrwStatusSync(),
  watchDeleteStatus(),
  watchEditStatus()
];
