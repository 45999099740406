// @flow

import React from "react";
import { toast } from "@unifize/sarah";
import {
  put,
  call,
  takeLatest,
  takeEvery,
  select,
  delay
} from "redux-saga/effects";

import Toast from "src/components/messages/Prompt/Toast";
import * as atypes from "src/constants/actionTypes";
import * as prompts from "src/api/prompts";
import getAppState, { getUser } from "src/selectors";
import { getChatroomType } from "src/reducers";

import type { Action } from "src/types";

function* loadPromptRules({ payload }: Action): any {
  try {
    const conversationType = getChatroomType(
      yield select(getAppState),
      `${payload.id}`
    );

    if (conversationType === "workflow") {
      const { prompt } = yield call(prompts.getPromptFields, payload.id);

      if (prompt.checklist || []) {
        const primaryChecklist = prompt.checklist[0];
        const promptFields = {
          [payload.id]: { ...primaryChecklist }
        };

        delay(2000);
        yield put({
          type: atypes.SET_PROMPT_FIELDS,
          payload: { promptFields }
        });
      }
    }
  } catch (err) {
    yield put({
      type: atypes.GET_PROMPT_FIELDS_FAILURE,
      payload: err
    });
  }
}

function* watchLoadPromptRules(): any {
  yield takeLatest(atypes.SET_CURRENT_CHATROOM_SUCCESS, loadPromptRules);
}

function* refetchPromptFields({ payload }: Action): any {
  try {
    const { prompt } = yield call(prompts.getPromptFields, payload.roomId);
    if (prompt.checklist || []) {
      const primaryChecklist = prompt.checklist[0];
      const promptFields = {
        [payload.roomId]: { ...primaryChecklist }
      };

      yield put({
        type: atypes.SET_PROMPT_FIELDS,
        payload: { promptFields }
      });
    }
  } catch (err) {
    yield put({
      type: atypes.REFETCH_PROMPT_FIELD_FAILURE,
      payload: { err }
    });
  }
}

function* watchRefetchPromptField(): any {
  yield takeEvery(atypes.REFETCH_PROMPT_FIELD, refetchPromptFields);
}

function* dismissPrompt({ payload }: Action): any {
  try {
    const { room, field } = payload;
    const { uid } = yield select(getUser);
    yield call(prompts.dismissPrompt, { room, field, uid });
    yield put({
      type: atypes.DISMISS_PROMPT_SUCCESS,
      payload: { [room]: field }
    });
    toast.success(<Toast />);
  } catch (err) {
    yield put({
      type: atypes.DISMISS_PROMPT_FAILURE,
      payload: err
    });
  }
}

function* watchDismissPrompt(): any {
  yield takeEvery(atypes.DISMISS_PROMPT_REQUEST, dismissPrompt);
}

function* undoDismissPrompt({ payload }: Action): any {
  try {
    const { uid } = yield select(getUser);

    yield call(prompts.undoDismiss, { room: payload, uid });
    yield put({
      type: atypes.UNDO_DISMISS_PROMPT_SUCCESS,
      payload
    });
  } catch (err) {
    yield put({
      type: atypes.UNDO_DISMISS_PROMPT_FAILURE,
      payload: err
    });
  }
}

function* watchUndoDismissPrompt(): any {
  yield takeEvery(atypes.UNDO_DISMISS_PROMPT_REQUEST, undoDismissPrompt);
}

export default [
  watchLoadPromptRules(),
  watchRefetchPromptField(),
  watchDismissPrompt(),
  watchUndoDismissPrompt()
];
