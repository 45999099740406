// @flow

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HStack, Text, Box } from "@chakra-ui/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "@unifize/sarah";

import { getChatRoomEmail } from "src/reducers";
import { generateChatroomEmail } from "src/actions/email";
import type { RoomId } from "src/types";
import Icon from "src/icons";

import * as colors from "src/styles/constants/colors";
import * as styles from "./styles";

type Props = {
  roomId: RoomId,
  setIsMoreMenuOpen: (value: boolean) => void
};
const ForwardAddress = ({ roomId, setIsMoreMenuOpen }: Props) => {
  const email = useSelector(({ app }) => getChatRoomEmail(app));
  const dispatch = useDispatch();

  useEffect(() => {
    if (email === "" && roomId) {
      dispatch(generateChatroomEmail(roomId));
    }
  }, [roomId, generateChatroomEmail]);

  const handleCopy = () => {
    setIsMoreMenuOpen(false);
    toast.success(
      "Email address copied",
      "Use copied email address to forward your mail to this conversation"
    );
  };
  return (
    <CopyToClipboard text={email || ""} onCopy={() => handleCopy()}>
      <HStack sx={styles.MenuItem} w={"100%"} tabIndex={0}>
        <Icon type="email" height={16} width={16} stroke={colors.active} />;
        <Text>Forward mail here</Text>
        <Box display={"flex"} flex={1} justifyContent={"flex-end"}>
          <Icon type="copy" height={16} width={16} fill={colors.inkLightest} />
        </Box>
      </HStack>
    </CopyToClipboard>
  );
};

export default ForwardAddress;
