// @flow

import { toast } from "@unifize/sarah";
import * as R from "ramda";
import {
  all,
  call,
  put,
  takeEvery,
  fork,
  take,
  select,
  delay,
  takeLatest
} from "redux-saga/effects";
import connection, { rsf } from "src/db";

import * as atypes from "src/constants/actionTypes";
import * as chatroom from "src/api/chatroom";
import * as actions from "src/actions/chatroom";
import getAppState, {
  getUserMembership,
  getLastOrg,
  getIsSrw
} from "src/selectors";

import type { Action } from "src/types";

function* getCommonMembers({ payload }: Action): any {
  try {
    const members = yield call(chatroom.getCommonMembers, payload.chatrooms);
    yield put({
      type: atypes.GET_COMMON_MEMBERS_SUCCESS,
      payload: {
        members
      }
    });
  } catch (error) {
    yield put({
      type: atypes.GET_COMMON_MEMBERS_FAILURE,
      payload: {
        error
      }
    });

    toast.error(`Unable to fetch common members`);
  }
}

function* watchGetCommonMembers(): any {
  yield takeEvery(atypes.GET_COMMON_MEMBERS_REQUEST, getCommonMembers);
}

function* assignMembersAndGroups(action: Action) {
  try {
    const response = yield call(
      chatroom.assignMembersAndGroups,
      action.payload
    );
    const orgId = yield select(getLastOrg);

    if (response.members) {
      yield put({
        type: atypes.ADD_ROOM_MEMBER_SUCCESS,
        payload: R.mergeAll(
          response.members.map(member => ({
            [member.uid]: {
              ...member,
              email:
                `${orgId}` === `${208}` || `${orgId}` === `${217}`
                  ? ""
                  : member.email
            }
          }))
        )
      });
    }

    if (response.groups) {
      if (response.groups.length > 0) {
        yield put({
          type: atypes.ADD_ROOM_GROUP_SUCCESS,
          payload: response.groups
        });
      }
    }
  } catch (error) {
    toast.error("Failed to add participant(s)");
    yield put({
      type: atypes.ADD_ROOM_MEMBER_REQUEST_FAILURE,
      payload: action.payload
    });
  }
}

function* watchAssignMembers(): any {
  yield takeEvery(atypes.ADD_ROOM_MEMBER_REQUEST, assignMembersAndGroups);
}

function* removeMembers(action: Action) {
  try {
    yield call(chatroom.removeMembers, action.payload);
    yield put({
      type: atypes.REMOVE_ROOM_MEMBER_REQUEST_SUCCESS,
      payload: action.payload
    });
  } catch (error) {
    toast.error(`Error in removing room member`);

    yield put({
      type: atypes.REMOVE_ROOM_MEMBER_REQUEST_FAILURE,
      payload: { ...action.payload, error }
    });
  }
}

function* watchRemoveMembers(): any {
  yield takeEvery(atypes.REMOVE_ROOM_MEMBER_REQUEST, removeMembers);
}

function* watchSrwRoomMemberList(): any {
  try {
    const { payload } = (yield all([
      take(atypes.SRW_SIGNED_IN),
      take(atypes.SRW_CURRENT_CHATROOM)
    ]))[1];
    yield fork(
      rsf.firestore.syncCollection,
      connection().collection(`chatrooms/${payload}/members`),
      {
        successActionCreator: actions.loadRoomMembers,
        failureActionCreator: actions.loadRoomMembersError
      }
    );
  } catch (error) {
    yield put({
      type: atypes.ROOM_MEMBER_SYNC_FAILURE,
      payload: { error }
    });
  }
}

function* updatePendingMembers({ payload }: Action): any {
  try {
    const pendingMembers = [];

    // Using doc instead of doc changes since the whole data is needed for sorting
    for (const doc of payload.snapshot.docs) {
      let member = {};

      try {
        member = doc.data();
      } catch (error) {
        console.error("User is empty. Error:", error);
      }

      const { pending } = member;
      if (pending) {
        pendingMembers.push(doc.id);
      }
    }

    yield put({
      type: atypes.UPDATE_PENDING_MEMBERS_SUCCESS,
      payload: {
        pendingMembers
      }
    });
  } catch (err) {
    console.error(err);
  }
}

function* watchUpatePendingMembers(): any {
  yield takeEvery(atypes.UPDATE_PENDING_MEMBERS_REQUEST, updatePendingMembers);
}

function* requestRoomMemberList({ payload }: Action): any {
  try {
    yield put({
      type: atypes.STOP_ROOM_MEMBER_SYNC,
      payload: {}
    });

    yield fork(
      rsf.firestore.syncCollection,
      connection().collection(`chatrooms/${payload.id}/members`),
      {
        successActionCreator: actions.updatePendingMembers,
        failureActionCreator: actions.loadRoomMembersError
      }
    );

    delay(4000);

    let membership = yield select(getUserMembership);

    if (membership.contains(parseInt(payload.id, 10))) {
      yield put({
        type: atypes.START_ROOM_MEMBER_SYNC,
        payload: {
          roomId: payload.id
        }
      });
    } else {
      yield take(atypes.GET_USER_MEMBERSHIP_SUCCESS);
      membership = yield select(getUserMembership);

      if (membership.contains(parseInt(payload.id, 10))) {
        yield put({
          type: atypes.START_ROOM_MEMBER_SYNC,
          payload: {
            roomId: payload.id
          }
        });
      }
    }

    // Additional API call to populate the roomMembers and roomGroups values
    yield put({
      type: atypes.LOAD_CHATROOM_REQUEST,
      payload: {
        id: payload.id
      }
    });
  } catch (error) {
    console.log(error);
  }
}

function* watchRoomMembersList(): any {
  yield takeLatest(atypes.SET_CURRENT_CHATROOM_SUCCESS, requestRoomMemberList);
}

function* watchConversationDialogRoomMembers(): any {
  yield takeLatest(atypes.OPEN_CONVERSATION_MODAL, requestRoomMemberList);
}

function* watchSyncCurrentRoomMembers(): any {
  yield takeLatest(atypes.SYNC_ROOM_MEMBERS_REQUEST, requestRoomMemberList);
}

function* syncCurrentConversationMembers(): any {
  try {
    const currentChatroomId = (yield select(getAppState)).chatRooms.current;
    const currentChatroomAddress = (yield select(getAppState)).chatRooms.byId[
      currentChatroomId
    ]?.address;

    if (currentChatroomId) {
      // Sync current chatroom members only if url contains /conversation
      if (R.includes("conversation", window.location.href || "")) {
        yield put({
          type: atypes.SYNC_ROOM_MEMBERS_REQUEST,
          payload: {
            id: currentChatroomId,
            address: currentChatroomAddress
          }
        });
      }
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

function* watchSyncCurrentConversationMembers(): any {
  yield takeLatest(
    atypes.CLOSE_CONVERSATION_MODAL,
    syncCurrentConversationMembers
  );
}

function* removeCommonMembers({ payload }: Action): any {
  try {
    yield call(chatroom.removeCommonMembers, payload);
    yield put({
      type: atypes.REMOVE_COMMON_MEMBERS_SUCCESS,
      payload: {}
    });
    toast.success("Removed members from selected conversation");
  } catch (error) {
    toast.error("Unable to remove member from selected conversation");
    yield put({
      type: atypes.REMOVE_COMMON_MEMBERS_FAILURE,
      payload: error
    });
  }
}

function* watchRemoveCommonMembers(): any {
  yield takeEvery(atypes.REMOVE_COMMON_MEMBERS_REQUEST, removeCommonMembers);
}

function* bulkAddMembers({ payload }: Action): any {
  try {
    const selectedRooms = (yield select(getAppState)).workflow.selectedRows;

    yield call(chatroom.addBulkMembers, {
      ...payload,
      chatrooms: selectedRooms
    });

    yield put({
      type: atypes.BULK_ADD_ROOM_MEMBERS_SUCCESS,
      payload: {}
    });

    toast.success("Members have been added to conversations");
  } catch (error) {
    toast.error("Error adding members");
    yield put({
      type: atypes.BULK_ADD_ROOM_MEMBERS_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchBulkAddMembers(): any {
  yield takeEvery(atypes.BULK_ADD_ROOM_MEMBERS_REQUEST, bulkAddMembers);
}

function* loadRoomMembers({ payload }: Action): any {
  try {
    const userById = (yield select(getAppState)).users.byId;
    const currentChatroomId = (yield select(getAppState)).chatRooms.current;
    const isLiteApp = yield select(getIsSrw);
    const currentChatroomAddress = (yield select(getAppState)).chatRooms.byId[
      currentChatroomId
    ]?.address;

    if (R.isEmpty(userById)) {
      yield take(atypes.SYNC_USERS_SUCCESS);
    }

    const pendingMembers = [];

    // Using doc instead of doc changes since the whole data is needed for sorting
    for (const doc of payload.snapshot.docs) {
      let member = {};

      try {
        member = doc.data();
      } catch (error) {
        console.error("User is empty. Error:", error);
      }

      const { pending } = member;
      if (pending) {
        pendingMembers.push(doc.id);
      }
    }

    yield put({
      type: atypes.ROOM_MEMBER_SYNC_SUCCESS,
      payload: {
        members: [],
        pendingMembers
      }
    });

    yield put({
      type: atypes.LOAD_CHATROOM_REQUEST,
      payload: {
        id: currentChatroomId
      }
    });

    if (isLiteApp && currentChatroomAddress) {
      yield put({
        type: atypes.HIDE_PARTICIPANT_LOADER,
        payload: {
          address: currentChatroomAddress
        }
      });
    }
  } catch (e) {
    yield put({
      type: atypes.ROOM_MEMBER_SYNC_FAILURE,
      payload: e
    });
  }
}

function* watchLoadRoomMembers(): any {
  yield takeEvery(atypes.LOAD_ROOM_MEMBERS_SUCCESS, loadRoomMembers);
}

export default [
  watchSyncCurrentRoomMembers(),
  watchSyncCurrentConversationMembers(),
  watchConversationDialogRoomMembers(),
  watchGetCommonMembers(),
  watchAssignMembers(),
  watchRemoveMembers(),
  watchRoomMembersList(),
  watchRemoveCommonMembers(),
  watchBulkAddMembers(),
  watchLoadRoomMembers(),
  watchSrwRoomMemberList(),
  watchUpatePendingMembers()
];
