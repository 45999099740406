// @flow

import * as R from "ramda";
import { toast } from "@unifize/sarah";
import { put, takeEvery, select, call } from "redux-saga/effects";

import * as atypes from "src/constants/actionTypes";
import { getUser } from "src/selectors";
import * as api from "src/api/userAnalytics";
import type { Action } from "src/types";

function* showUserAnalytics({ meta }: Action): any {
  try {
    const { uid } = yield select(getUser);
    const query = (meta || {}).query || {};
    const sort = query.sort || [];

    if (!uid) {
      yield put({ type: atypes.SIGN_IN });
      yield put({
        type: atypes.SET_REQUESTED_PAGE,
        payload: {
          page: "user-analytics",
          query: {
            ...query,
            sort: R.type(sort) === "String" ? [sort] : sort
          }
        }
      });
    } else {
      yield put({
        type: atypes.SET_USER_ANALYTICS_SUCCESS,
        payload: {
          ...query,
          sort: R.type(sort) === "String" ? [sort] : sort
        }
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SET_USER_ANALYTICS_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchShowUserAnalytics(): any {
  yield takeEvery(atypes.SET_USER_ANALYTICS_REQUEST, showUserAnalytics);
}

function* getUserAnalytics(): any {
  try {
    const filter = `${
      (window.location.href || "").split(`/user-analytics`)[1]
    }`;

    const { userDetails: result, readCountComputedAt } = yield call(
      api.getUserAnalytics,
      {
        filter
      }
    );

    yield put({
      type: atypes.GET_USER_ANALYTICS_SUCCESS,
      payload: { result, readCountComputedAt }
    });

    const totalKeys = [
      "createdAll",
      "createdOverdue",
      "createdCompleted",
      "createdPending",
      "pendingAll",
      "pendingOverdue",
      "pendingUnread",
      "pendingOwner",
      "pendingCritical",
      "completedAll",
      "completedOwner",
      "completedCreator",
      "completedBy"
    ];

    let total = {};
    let average = {};

    result.forEach(user => {
      totalKeys.forEach(column => {
        total[column] = (user[column] || 0) + (total[column] || 0);
      });
    });

    totalKeys.forEach(column => {
      average[column] = (
        (total[column] || 0) / (result.length > 0 ? result.length : 1)
      ).toFixed(2);
    });

    yield put({
      type: atypes.GET_USER_ANALYTICS_TOTAL,
      payload: {
        total: R.pickAll(
          [
            "createdCompleted",
            "createdPending",
            "pendingUnread",
            "pendingOwner",
            "completedOwner",
            "completedCreator",
            "completedBy"
          ],
          total
        ),
        average
      }
    });
  } catch (error) {
    yield put({
      type: atypes.GET_USER_ANALYTICS_FAILURE,
      payload: { error }
    });
    toast.error(`Error fetching user analytics displaying old value`);
  }
}

function* watchGetUserAnalytics(): any {
  yield takeEvery(atypes.SET_USER_ANALYTICS_SUCCESS, getUserAnalytics);
}

export default [watchShowUserAnalytics(), watchGetUserAnalytics()];
