// @flow

import { css } from "@emotion/react";

import * as colors from "src/styles/constants/colors";
import "src/styles/fonts.css";

const GlobalStyle = css`
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  strong {
    font-weight: 700;
  }

  :focus {
    outline: none !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 700;
  }

  h1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  h2 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  h3 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  h4 {
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 600;
  }

  h5 {
    font-size: 0.625rem;
    line-height: 1;
  }

  html {
    overflow: hidden;
    scroll-behavior: smooth;
  }

  html,
  body {
    overscroll-behavior-y: contain;
  }

  .react-tagsinput--focused {
    border-color: rgb(77, 144, 254);
    outline: -webkit-focus-ring-color auto 5px;
    outline-color: -webkit-focus-ring-color;
  }

  // Disabling auto fill color
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${colors.brightLight} inset !important;
  }

  -webkit-font-smoothing: antialiased;

  /* customize scrollbar css */

  ::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    width: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.secondary}b3;
    border-radius: 4px;
    width: 8px;
    min-height: 64px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  a {
    color: ${colors.active};
    text-decoration: none;
  }

  button {
    border: none;
    cursor: pointer;
  }

  span,
  div,
  li {
    outline: none;
  }

  ::-moz-selection {
    background: ${colors.unifizeBlue};
    color: ${colors.brightLight};
  }
  ::selection {
    background: ${colors.unifizeBlue};
    color: ${colors.brightLight};
  }

  .react-tiny-popover-container {
    overflow: visible !important;
  }

  .public-DraftEditorPlaceholder-root {
    position: absolute;
    font-size: 14px;
    color: ${colors.grey5};
  }

  input[type="text"],
  input[type="number"],
  input[type="password"],
  textarea {
    background: ${colors.brightLight};
    outline: none !important;
  }

  body {
    color: ${colors.primary};
    font-weight: 400;
  }

  html {
    font-size: 16px;
    background-color: ${colors.brightLight};
    font-family: "Lato", sans-serif;
    line-height: 16px;
  }
  * {
    font-family: "Lato", sans-serif !important;
  }
`;

export default GlobalStyle;
