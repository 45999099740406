// @flow

import React from "react";
import { HStack, Text, Box } from "@chakra-ui/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Icon from "src/icons";
import { toast } from "@unifize/sarah";
import { active } from "src/styles/constants/colors";

import * as styles from "./styles";
import * as colors from "src/styles/constants/colors";

type Props = {
  setIsMoreMenuOpen: (value: boolean) => void
};
const ConversationLink = ({ setIsMoreMenuOpen }: Props) => {
  const handleCopy = () => {
    setIsMoreMenuOpen(false);
    toast.success(" Link copied", "Conversation link has been copied");
  };
  return (
    <CopyToClipboard
      text={window.location.href || ""}
      onCopy={() => handleCopy()}
    >
      <HStack sx={styles.MenuItem} w={"100%"} tabIndex={0}>
        <Icon type="urlCopy" height={16} width={16} stroke={active} />
        <Text>Conversation Link</Text>
        <Box display={"flex"} py={1} flex={1} justifyContent={"flex-end"}>
          <Icon type="copy" height={16} width={16} fill={colors.inkLightest} />
        </Box>
      </HStack>
    </CopyToClipboard>
  );
};

export default ConversationLink;
