// @flow

import { toast } from "@unifize/sarah";
import * as R from "ramda";
import Papa from "papaparse";
import type { CsvUploadedChecklistField } from "src/types";
import * as morpheus from "src/utils/morpheus";
import { revisionDefaultSettings } from "src/utils/morpheus";

export const getFieldsFromCSV = (
  csvText: string
): CsvUploadedChecklistField[] => {
  const parsedResult = Papa.parse(csvText);

  if (!R.isEmpty(parsedResult.errors)) {
    console.error(parsedResult);
    toast.error("Invalid format uploaded");
    throw new Error("Invalid format uploaded");
  }

  const lines = parsedResult.data;

  const columns = lines[0];
  const data = lines.slice(1);

  const labelColIndex = columns.indexOf("Label");
  const fieldTypeColIndex = columns.indexOf("Field Type");

  if (labelColIndex === -1 || fieldTypeColIndex === -1) {
    toast.error("Invalid format uploaded");
    throw new Error("Invalid format uploaded");
  }

  return data.map(item => ({
    label: item[labelColIndex],
    fieldType: item[fieldTypeColIndex]
  }));
};

const supportedFieldsFromJSON = [
  "text",
  "date",
  "number",
  "select",
  "subSection",
  "user",
  "section",
  "pdf",
  "file",
  "form",
  "conversation",
  "childConversation",
  "approval",
  "revision"
];

const fieldSettingsAdjuster = {
  user: {
    users: []
  },
  section: {
    status: []
  },
  pdf: {
    templateId: "",
    targetField: null,
    pdf: []
  },
  file: {
    defaultFile: []
  },
  form: {
    selectedForms: []
  },
  childConversation: {
    fields: [],
    workflow: null
  },
  conversation: {
    fields: [],
    workflow: null
  },
  approval: {
    users: [],
    lockedFields: [],
    afterApproval: {
      changeStatusTo: null
    },
    afterRevision: {
      changeStatusTo: null
    }
  },
  revision: revisionDefaultSettings
};

export const getFieldsFromJSON = (jsonText: string) => {
  try {
    const parsedJSON = JSON.parse(jsonText);

    const supportedFields = R.filter(field =>
      supportedFieldsFromJSON.includes(field.type)
    )(parsedJSON);

    const fieldsWithSettingsAdjusted = supportedFields.map(field => {
      const settingsAdjuster = fieldSettingsAdjuster[field.type];

      let fieldSettings = settingsAdjuster
        ? R.mergeDeepRight(field.settings, settingsAdjuster)
        : field.settings;

      // Get latest version of settings
      if (morpheus[field.type]) {
        fieldSettings = morpheus[field.type](fieldSettings);
      }

      // Remove conditions
      fieldSettings = R.omit(["conditionBlocks"], fieldSettings);

      return {
        label: field.label,
        fieldType: field.type,
        settings: fieldSettings
      };
    });

    return fieldsWithSettingsAdjusted;
  } catch (error) {
    console.error(error);
    return [];
  }
};
