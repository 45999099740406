// @flow

import { normalize } from "normalizr";
import { toast } from "@unifize/sarah";
import {
  call,
  put,
  takeEvery,
  fork,
  select,
  take,
  takeLatest
} from "redux-saga/effects";
import { rsf } from "src/db";
import * as R from "ramda";

import { roles as rolesSchema } from "src/actions/schema";
import { loadComponentPermissions } from "src/actions/roleManagement";
import * as atypes from "src/constants/actionTypes";
import { routeComponentPermissionsMap } from "src/constants/roleManagement";
import * as api from "src/api/roleManagement";
import type { Action, ComponentPermissionName } from "src/types";
import getAppState, { getLastOrg, getCurrentUserId } from "src/selectors";
import {
  getRoleId,
  getComponentPermissionsSynced,
  getComponentPermission,
  getRoleTitles,
  getPermissions
} from "src/reducers";

function* getAllRoles(): any {
  try {
    const response = yield call(api.getAllRoles);
    const allRoles = normalize(response, rolesSchema);

    allRoles["result"] = R.uniq(allRoles.result || []);

    yield put({
      type: atypes.GET_ALL_ROLES_SUCCESS,
      payload: {
        allRoles
      }
    });
  } catch (error) {
    toast.error("Unable to fetch roles");
    yield put({
      type: atypes.GET_ALL_ROLES_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchGetAllRoles(): any {
  yield takeEvery(atypes.GET_ALL_ROLES_REQUEST, getAllRoles);
}

function* addUserToRole({ payload }: Action): any {
  try {
    const { id, members } = payload;
    yield call(api.assignUsersToRole, id, members);
    const response = yield call(api.getAllRoles);
    const allRoles = normalize(response, rolesSchema);

    yield put({
      type: atypes.ADD_ROLE_MEMBER_SUCCESS,
      payload: {
        allRoles
      }
    });
    toast.success("User has been added to the role");
  } catch (error) {
    toast.error("Unable to update role");
    yield put({
      type: atypes.ADD_ROLE_MEMBER_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchAddUserToRole(): any {
  yield takeEvery(atypes.ADD_ROLE_MEMBER_REQUEST, addUserToRole);
}

function* removeUserFromRole({ payload }: Action): any {
  try {
    const { id, uid } = payload;
    yield call(api.removeUserFromRole, id, uid);
    const response = yield call(api.getAllRoles);
    const allRoles = normalize(response, rolesSchema);

    yield put({
      type: atypes.REMOVE_ROLE_MEMBER_SUCCESS,
      payload: {
        allRoles
      }
    });
    toast.success("User removed");
  } catch (error) {
    toast.error("Unable to remove user");
    yield put({
      type: atypes.REMOVE_ROLE_MEMBER_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchRemoveMemberFromRole(): any {
  yield takeEvery(atypes.REMOVE_ROLE_MEMBER_REQUEST, removeUserFromRole);
}

function* getAllPermissions(): any {
  try {
    const response = yield call(api.getAllPermissions);
    const allPermissions = [];

    response.forEach(permission => {
      // If neither component ID or resource ID exist then ignore
      if (
        // $FlowFixMe
        !permission["resourceId"] &&
        // $FlowFixMe
        !permission["componentId"] &&
        // $FlowFixMe
        !permission["resource-id"] &&
        // $FlowFixMe
        !permission["component-id"]
      )
        return;

      allPermissions.push({
        ...permission
      });
    });

    yield put({
      type: atypes.GET_ALL_PERMISSIONS_SUCCESS,
      payload: {
        allPermissions
      }
    });
  } catch (error) {
    console.error(error);
    toast.error("Unable to fetch permissions");
    yield put({
      type: atypes.GET_ALL_PERMISSIONS_FAILURE,
      payload: {
        error
      }
    });
  }
}

// Fetch all roles and permissions when users
// are synced the first time
function* watchUserSync(): any {
  yield take(atypes.SYNC_USERS_SUCCESS);
  yield call(getAllRoles);
  yield call(getAllPermissions);
}

function* watchGetAllPermissions(): any {
  yield takeEvery(atypes.GET_ALL_PERMISSIONS_REQUEST, getAllPermissions);
}

function* assignResourcePermissionToRole({ payload }: Action): any {
  const { id, rid, vid } = payload;

  try {
    yield call(api.assignResourcePermissionToRole, id, rid, vid);
    toast.success("Permission updated");
    yield put({
      type: atypes.SET_RESOURCE_PERMISSION_SUCCESS,
      payload: {
        id: `${rid}-${vid}`
      }
    });
  } catch (error) {
    toast.error("Unable to update permission");
    yield put({
      type: atypes.SET_RESOURCE_PERMISSION_FAILURE,
      payload: {
        id: `${rid}-${vid}`
      }
    });
  } finally {
    yield put({
      type: atypes.GET_ALL_ROLES_REQUEST
    });
  }
}

function* watchAssignResourcePermissionToRole(): any {
  yield takeEvery(
    atypes.SET_RESOURCE_PERMISSION_REQUEST,
    assignResourcePermissionToRole
  );
}

function* removeResourcePermissionToRole({ payload }: Action): any {
  try {
    const { id, rid, vid } = payload;
    yield call(api.removeResourcePermissionToRole, id, rid, vid);
    toast.success("Permission removed");
    yield put({
      type: atypes.REMOVE_RESOURCE_PERMISSION_SUCCESS,
      payload: {}
    });
  } catch (error) {
    toast.error("Unable to remove permission");
    yield put({
      type: atypes.REMOVE_RESOURCE_PERMISSION_FAILURE,
      payload: {
        error
      }
    });
  } finally {
    yield put({
      type: atypes.GET_ALL_ROLES_REQUEST
    });
  }
}

function* watchRemoveResourcePermissionToRole(): any {
  yield takeEvery(
    atypes.REMOVE_RESOURCE_PERMISSION_REQUEST,
    removeResourcePermissionToRole
  );
}

function* assignComponentPermissionToRole({ payload }: Action): any {
  const { id, cid } = payload;

  try {
    yield call(api.assignComponentPermissionToRole, id, cid);
    toast.success("Permission updated");
    yield put({
      type: atypes.SET_COMPONENT_PERMISSION_SUCCESS,
      payload: {
        id: `${cid}`
      }
    });
  } catch (error) {
    toast.error("Unable to update permission");
    yield put({
      type: atypes.SET_COMPONENT_PERMISSION_FAILURE,
      payload: {
        id: `${cid}`
      }
    });
  } finally {
    yield put({
      type: atypes.GET_ALL_ROLES_REQUEST
    });
  }
}

function* watchAssignComponentPermissionToRole(): any {
  yield takeEvery(
    atypes.SET_COMPONENT_PERMISSION_REQUEST,
    assignComponentPermissionToRole
  );
}

function* removeComponentPermissionToRole({ payload }: Action): any {
  try {
    const { id, cid } = payload;
    yield call(api.removeComponentPermissionToRole, id, cid);
    toast.success("Permission removed");
    yield put({
      type: atypes.REMOVE_COMPONENT_PERMISSION_SUCCESS,
      payload: {}
    });
  } catch (error) {
    toast.error("Unable to remove permission");
    yield put({
      type: atypes.REMOVE_COMPONENT_PERMISSION_FAILURE,
      payload: {
        error
      }
    });
  } finally {
    yield put({
      type: atypes.GET_ALL_ROLES_REQUEST
    });
  }
}

function* watchRemoveComponentPermissionToRole(): any {
  yield takeEvery(
    atypes.REMOVE_COMPONENT_PERMISSION_REQUEST,
    removeComponentPermissionToRole
  );
}

function* addCustomRole({ payload }: Action): any {
  try {
    const { title } = payload;
    yield call(api.addCustomRole, title);
    const response = yield call(api.getAllRoles);
    const allRoles = normalize(response, rolesSchema);

    toast.success("Custom role added");
    yield put({
      type: atypes.ADD_CUSTOM_ROLE_SUCCESS,
      payload: {
        allRoles
      }
    });
  } catch (error) {
    toast.error("Unable to add custom role");
    yield put({
      type: atypes.ADD_CUSTOM_ROLE_FAILURE,
      payload: {
        error
      }
    });
  } finally {
    yield put({
      type: atypes.GET_ALL_ROLES_REQUEST
    });
  }
}

function* watchAddCustomRole(): any {
  yield takeEvery(atypes.ADD_CUSTOM_ROLE_REQUEST, addCustomRole);
}

function* deleteCustomRole({ payload }: Action): any {
  try {
    const { id } = payload;
    yield call(api.deleteCustomRole, id);
    const response = yield call(api.getAllRoles);
    const allRoles = normalize(response, rolesSchema);

    toast.success("Custom role deleted");
    yield put({
      type: atypes.DELETE_CUSTOM_ROLE_SUCCESS,
      payload: {
        allRoles
      }
    });
  } catch (error) {
    toast.error("Unable to delete custom role");
    yield put({
      type: atypes.DELETE_CUSTOM_ROLE_FAILURE,
      payload: {
        error
      }
    });
  } finally {
    yield put({
      type: atypes.GET_ALL_ROLES_REQUEST
    });
  }
}

function* watchDeleteCustomRole(): any {
  yield takeEvery(atypes.DELETE_CUSTOM_ROLE_REQUEST, deleteCustomRole);
}

function* updateCustomRole({ payload }: Action): any {
  try {
    const { id, updatedTitle } = payload;
    yield call(api.updateCustomRole, id, updatedTitle);
    toast.success("Custom role updated");
    yield put({
      type: atypes.UPDATE_CUSTOM_ROLE_SUCCESS
    });
  } catch (error) {
    toast.error("Unable to update role");
    yield put({
      type: atypes.UPDATE_CUSTOM_ROLE_FAILURE,
      payload: {
        error
      }
    });
  } finally {
    yield put({
      type: atypes.GET_ALL_ROLES_REQUEST
    });
  }
}

function* watchUpdateCustomRole(): any {
  yield takeEvery(atypes.UPDATE_CUSTOM_ROLE_REQUEST, updateCustomRole);
}

function* startComponentPermissionsSync(): any {
  try {
    const orgId = yield select(getLastOrg);
    const currentUser = yield select(getCurrentUserId);

    const syncingUsers = yield select(state => state.app.users.loading);
    if (syncingUsers) {
      yield take(atypes.SYNC_USERS_SUCCESS);
    }

    const role = getRoleId(yield select(getAppState), currentUser);

    const docPath = `orgs/${orgId}/roleData/${role}`;

    const snapshot = yield call(rsf.firestore.getDocument, docPath);
    yield put(loadComponentPermissions(snapshot));

    yield fork(rsf.firestore.syncDocument, docPath, {
      successActionCreator: (snapshot: Object) =>
        loadComponentPermissions(snapshot)
    });
  } catch (error) {
    console.error(error);
  }
}

function* watchComponentPermissionsSync(): any {
  yield takeEvery(atypes.API_AUTH_SUCCESS, startComponentPermissionsSync);
}

function* loadUserComponentPermissions({ payload }: Action): any {
  try {
    const data = payload.snapshot.data();
    const components = data?.components || {};

    // In firestore if `inverted` is true then hide the component and vice versa
    yield put({
      type: atypes.SYNC_COMPONENT_PERMISSIONS_SUCCESS,
      payload: R.mapObjIndexed(value => !value.inverted, components || {})
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: atypes.SYNC_COMPONENT_PERMISSIONS_FAILURE,
      payload: error
    });
  }
}

function* watchLoadComponentPermissions(): any {
  yield takeLatest(
    atypes.LOAD_COMPONENT_PERMISSIONS,
    loadUserComponentPermissions
  );
}

function* checkRouteAuthorization({ type }: Action): any {
  try {
    const componentPermissionsSynced = getComponentPermissionsSynced(
      yield select(getAppState)
    );
    if (!componentPermissionsSynced) {
      yield take(atypes.SYNC_COMPONENT_PERMISSIONS_SUCCESS);
    }

    const allPermissions = getPermissions(yield select(getAppState));
    if (R.isEmpty(allPermissions)) {
      yield take(atypes.GET_ALL_PERMISSIONS_SUCCESS);
    }

    const state = yield select(getAppState);

    // Go through each component permission and only
    // when user has all the component permissions consider
    // route access authorized
    const authorized = routeComponentPermissionsMap[type].every(
      (componentPermission: ComponentPermissionName): boolean =>
        getComponentPermission(state, componentPermission)
    );

    if (!authorized) {
      yield put({
        type: atypes.SET_WATCH_SET_PREVIOUS_CHATROOM,
        payload: {}
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchRoutes(): any {
  // $FlowFixMe - Flow assumes R.keys returns string
  yield takeEvery(
    R.keys(routeComponentPermissionsMap),
    checkRouteAuthorization
  );
}

function* searchRoles({ payload }: Action): any {
  try {
    const app = yield select(getAppState);
    const searchQuery = R.toLower(payload.searchQuery);
    const roles: Array<{
      id: number,
      title: string,
      label: string
    }> = getRoleTitles(app);

    yield put({
      type: atypes.SEARCH_ROLES_SUCCESS,
      payload: {
        result: R.map(
          R.prop("id"),
          roles.filter(role => R.includes(searchQuery, R.toLower(role.label)))
        )
      }
    });
  } catch (error) {
    console.log(error);
  }
}

function* watchSearchRoles(): any {
  yield takeEvery(atypes.SEARCH_ROLES, searchRoles);
}

export default [
  watchGetAllRoles(),
  watchUserSync(),
  watchAddUserToRole(),
  watchRemoveMemberFromRole(),
  watchGetAllPermissions(),
  watchAssignResourcePermissionToRole(),
  watchRemoveResourcePermissionToRole(),
  watchAssignComponentPermissionToRole(),
  watchRemoveComponentPermissionToRole(),
  watchAddCustomRole(),
  watchDeleteCustomRole(),
  watchUpdateCustomRole(),
  watchComponentPermissionsSync(),
  watchLoadComponentPermissions(),
  watchRoutes(),
  watchSearchRoles()
];
