// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import { toast } from "@unifize/sarah";

import Icon from "src/icons";
import BlueOutlineButton from "src/components/Buttons/BlueOutline";
import { setForm } from "src/actions/forms";
import { setReports } from "src/actions/reports";
import { getWorkflowInstanceFilter } from "src/reducers";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  filter: WorkflowInstanceFilter,
  _setForm: Function,
  setReports: typeof setReports,
  reportId: ?number
};

const Refresh = ({ filter, _setForm, setReports, reportId }: Props) => {
  const handleReload = useCallback(() => {
    if (reportId) {
      setReports(reportId);
    } else {
      _setForm({ query: filter });
    }
    toast.success(`Refreshed forms table`);
  }, [filter, _setForm]);

  return (
    <BlueOutlineButton
      leftIcon={<Icon type="circularArrows" />}
      onClick={handleReload}
    >
      Refresh
    </BlueOutlineButton>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: getWorkflowInstanceFilter(app)
});

export default connect(mapStateToProps, {
  _setForm: setForm,
  setReports
})(Refresh);
