// @flow

import { takeEvery, put, call, select, takeLatest } from "redux-saga/effects";
import { toast } from "@unifize/sarah";
import * as R from "ramda";

import * as formApi from "src/api/forms";
import * as checklistApi from "src/api/checklist";
import * as atypes from "src/constants/actionTypes";
import getAppState, { getUser } from "src/selectors";
import {
  getForm as getFormData,
  getFormIdOfReport,
  getReportType,
  getReportFilters
} from "src/reducers";
import { toUrl } from "src/utils";

import type { Action, Form } from "src/types";

function* createForm({ payload }: Action): any {
  try {
    const form = yield call(formApi.createForm, payload.form);
    toast.success(`Create form success`);
    yield put({
      type: atypes.CREATE_FORM_SUCCESS,
      payload: { form }
    });

    yield put({
      type: atypes.SEARCH_FORMS_REQUEST,
      payload: ""
    });
  } catch (e) {
    if (e.status === 401) {
      toast.error(`You do not have enough permission`);
    } else {
      toast.error(`Error creating form`);
    }
    yield put({
      type: atypes.CREATE_FORM_FAILURE,
      payload: e
    });
  }
}

function* watchCreateForm(): any {
  yield takeEvery(atypes.CREATE_FORM_REQUEST, createForm);
}

// get all saved forms
function* getForm(): any {
  try {
    const forms = yield call(formApi.getAllFormTemplates);
    yield put({
      type: atypes.FETCH_FORMS_SUCCESS,
      payload: {
        forms: R.mergeAll(R.map(form => ({ [form.id]: form }), forms || []))
      }
    });
  } catch (error) {
    yield put({ type: atypes.FETCH_FORMS_FAILURE, payload: error });
  }
}

function* watchGetForm(): any {
  yield takeEvery(atypes.FETCH_FORMS_REQUEST, getForm);
}

function* watchGetFormInitial(): any {
  yield takeEvery(
    [atypes.API_AUTH_SUCCESS, atypes.SRW_SERVER_AUTH_SUCCESS],
    getForm
  );
}

const updateInstancesWithFormFields = () => {};

export function* watchFetchFormSuccess(): any {
  yield takeEvery(atypes.FETCH_FORMS_SUCCESS, updateInstancesWithFormFields);
}

export function* getFormById({ payload }: Action): any {
  try {
    const form = yield call(formApi.getFormById, payload.id);

    yield put({
      type: atypes.FETCH_FORM_SUCCESS,
      payload: { ...form }
    });

    if (payload.modal) {
      yield put({
        type: atypes.OPEN_FORM_MODAL,
        payload: {}
      });
    }

    return form;
  } catch (e) {
    toast.error(`Error Opening form`);

    yield put({
      type: atypes.FETCH_FORM_FAILURE,
      payload: { e }
    });
  }
}

function* watchGetFormById(): any {
  yield takeEvery(atypes.FETCH_FORM_REQUEST, getFormById);
}

function* updateForm({ payload }: Action): any {
  try {
    const checklist = yield call(formApi.updateForm, payload.form);

    toast.success(`Edit form success`);
    yield put({
      type: atypes.UPDATE_FORM_SUCCESS,
      payload: { checklist }
    });

    yield put({
      type: atypes.FETCH_FORMS_REQUEST,
      payload: {}
    });
  } catch (e) {
    toast.error(`Error editing form`);

    yield put({
      type: atypes.UPDATE_FORM_FAILURE,
      payload: { e }
    });
  }
}

function* watchUpdateForm(): any {
  yield takeEvery(atypes.UPDATE_FORM_REQUEST, updateForm);
}

function* getSearchedForms({ payload: searchQuery }: Action): any {
  try {
    const { byId } = (yield select(getAppState)).form;

    const result = R.map(
      R.prop("id"),
      R.filter((form: Form) => {
        return R.includes(R.toLower(searchQuery || ""), R.toLower(form.title));
      }, R.values(byId))
    );

    yield put({
      type: atypes.SEARCH_FORMS_SUCCESS,
      payload: { result }
    });
  } catch (error) {
    yield put({
      type: atypes.SEARCH_FORMS_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetSearchedForms(): any {
  yield takeEvery(atypes.SEARCH_FORMS_REQUEST, getSearchedForms);
}

function* showForms({ meta }: Action): any {
  try {
    const { uid } = yield select(getUser);
    const query = (meta || {}).query || {};
    const sort = query.sort || [];

    if (!uid) {
      yield put({ type: atypes.SIGN_IN });
      yield put({
        type: atypes.SET_REQUESTED_PAGE,
        payload: {
          page: "forms",
          query: {
            ...query,
            sort: R.type(sort) === "String" ? [sort] : sort
          }
        }
      });
    } else {
      yield put({
        type: atypes.SET_FORMS_SUCCESS,
        payload: {
          ...query,
          sort: R.type(sort) === "String" ? [sort] : sort
        }
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SET_FORMS_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchShowForms(): any {
  yield takeEvery(atypes.SET_FORMS_REQUEST, showForms);
}

function* getFormInstances({ type, payload }: Action): any {
  try {
    const isReport =
      getReportType(yield select(getAppState), payload.reportId) === "form";

    if (type === atypes.SET_REPORTS_SUCCESS && !isReport) {
      return;
    }

    const id = isReport
      ? getFormIdOfReport(yield select(getAppState), payload.reportId)
      : payload.id || null;
    const reportFilters = isReport
      ? getReportFilters(yield select(getAppState), payload.reportId)
      : null;
    const reportFilterQuery = reportFilters ? `?${toUrl(reportFilters)}` : null;

    if (id) {
      const filter =
        reportFilterQuery ||
        `${(window.location.href || "").split(`/forms`)[1]}`
          .replace(`id=${id}&`, "")
          .replace(`id=${id}`, "");

      const { results, totalCount } = yield call(formApi.getFormInstances, {
        id,
        filter: filter === "?" ? "" : `${filter}`
      });

      yield put({
        type: atypes.GET_FORM_INSTANCES_SUCCESS,
        payload: { results, totalCount }
      });
    }
  } catch (error) {
    toast.error(`Error fetching form data`);
    yield put({
      type: atypes.GET_FORM_INSTANCES_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetFormInstances(): any {
  yield takeEvery(
    [atypes.SET_FORMS_SUCCESS, atypes.SET_REPORTS_SUCCESS],
    getFormInstances
  );
}

function* uniqueProcessInstanceValues({ payload }: Action): any {
  try {
    let uniqueValues = yield call(formApi.getUniqFormInstanceValues, payload);

    yield put({
      type: atypes.GET_FORM_UNIQUE_INSTANCE_VALUES_SUCCESS,
      payload: {
        uniqueValues: uniqueValues
      }
    });
  } catch (error) {
    yield put({
      type: atypes.GET_FORM_UNIQUE_INSTANCE_VALUES_FAILURE,
      payload: { error }
    });
  }
}

function* watchUniqueFormInstanceValues(): any {
  yield takeEvery(
    atypes.GET_FORM_UNIQUE_INSTANCE_VALUES_REQUEST,
    uniqueProcessInstanceValues
  );
}

function* downloadFormInstance({ payload }: Action): any {
  try {
    const id = payload.formId ? parseInt(payload.formId, 10) : payload.formId;

    const filename = `${getFormData(yield select(getAppState), id).title}.csv`;

    if (window.location.href.includes("/forms")) {
      const filter = `${(window.location.href || "").split(`/forms?`)[1]}`
        .replace(`id=${id}&`, "")
        .replace(`id=${id}`, "");

      yield call(formApi.downloadProcessInstance, {
        formId: id,
        filter,
        filename
      });
    }
  } catch (error) {
    yield put({
      type: atypes.GET_FORM_INSTANCE_FILE_FAILURE,
      payload: { error }
    });
  }
}

function* watchDownloadFormInstance(): any {
  yield takeEvery(atypes.GET_FORM_INSTANCE_FILE_REQUEST, downloadFormInstance);
}

function* updateChecklistFromManageView({ payload }: Action): any {
  const oldValues = (yield select(getAppState)).form.instances?.[payload.index];

  try {
    yield put({
      type: atypes.CLOSE_CHECKLIST_MANAGE_VIEW,
      payload: {}
    });

    const response = yield call(checklistApi.setChecklistFieldValue, payload);

    let newValue = null;

    switch (payload.type) {
      case "text":
      case "number":
      case "date":
        newValue = payload.value;
        break;
      default:
        newValue = response.value;
    }

    yield put({
      type: atypes.UPDATE_CHECKLIST_FROM_FORM_MANAGE_VIEW_SUCCESS,
      payload: {
        index: payload.index,
        values: {
          ...oldValues,
          [payload.id]: newValue
        }
      }
    });

    toast.success("Checklist updated");
  } catch (error) {
    console.log(error);
    toast.error("Unable to update checklist");
    yield put({
      type: atypes.UPDATE_CHECKLIST_FROM_FORM_MANAGE_VIEW_FAILURE,
      payload: {
        index: payload.index,
        values: oldValues
      }
    });
  }
}

function* watchUpdateChecklistFromManageView(): any {
  yield takeLatest(
    atypes.UPDATE_CHECKLIST_FROM_FORM_MANAGE_VIEW_REQUEST,
    updateChecklistFromManageView
  );
}

export default [
  watchUpdateChecklistFromManageView(),
  watchDownloadFormInstance(),
  watchUniqueFormInstanceValues(),
  watchGetFormInstances(),
  watchShowForms(),
  watchGetFormInitial(),
  watchCreateForm(),
  watchGetForm(),
  watchUpdateForm(),
  watchGetFormById(),
  watchGetSearchedForms()
];
