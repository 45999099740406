// @flow

import * as R from "ramda";
import { toast } from "@unifize/sarah";
import {
  cancelled,
  call,
  all,
  put,
  takeEvery,
  select,
  throttle,
  take,
  delay,
  takeLatest,
  race
} from "redux-saga/effects";
import * as XLSX from "xlsx";
import * as moment from "moment";

import * as workflow from "src/api/workflow";
import * as chatroom from "src/api/chatroom";
import workflowColors from "src/styles/constants/chatblockColors";
import { ME_FILTER } from "src/constants/users";
import httpMethods from "src/constants/httpMethods";
import {
  getWorkflowTemplates,
  getWorkflowTitle,
  getAllUserIds,
  getInstanceFilter,
  getChatRoom,
  getWorkflowStatus,
  getNestedRows,
  getInstanceReportId,
  getReport,
  getChatroomTitle,
  getProcessTitle,
  getSequenceNo,
  getChatroomType,
  getWorkflowInstances as getWorkflowInstancesArr,
  getActiveStatus,
  getAllRecords
} from "src/reducers";
import { getCurrentLinkedFieldValue } from "src/reducers/bulkUpdates";
import * as api from "src/api/checklist";
import * as atypes from "src/constants/actionTypes";
import * as priority from "src/constants/priority";
import getAppState, {
  getCurrentUserId,
  getRecentWorkflows,
  getLastOrg,
  getUser,
  getChecklistFieldById,
  getChecklistFieldsById,
  getWorkflowInstanceFilter,
  getWorkflowInstances as getWorkflowInstancesById,
  getSelectedField
} from "src/selectors";
import * as selectors from "src/selectors";
import {
  formatMentions,
  getWorkflowsWithFormattedValues,
  capitalize,
  sanitizeTitle
} from "src/utils";
import defaultStatus from "src/constants/status";
import { getFormData } from "src/utils/checklist";
import { encodeColumnId } from "src/utils/reports";
import connection, { rsf } from "src/db";
import {
  formatChecklistFieldValue,
  formatManageViewFieldValue
} from "src/formatChecklistFieldValue";
import * as workflowActions from "src/actions/workflows";
import { setChecklistBuilderAttributes } from "src/actions/checklistBuilder";
import { showView } from "src/actions";
import { omitedFields } from "src/constants/processInstanceColumns";
import type { BulkUpdateProcessAction } from "src/actions/workflows";
import { convert as convertV1ToV2 } from "src/components/Status/SettingsModal/v1tov2Converter";
import { pathsWithMentions } from "../components/Manage/Builder/Checklist/SettingsBuilder/FieldSettings/AdvancedApproval/utils";

import type {
  AppState,
  Action,
  ApprovalFieldSettingsV2,
  WorkflowById,
  UsersById,
  StatusById,
  FieldsById,
  WorkflowInstances,
  UnifizeUser,
  LinkedFieldValue
} from "src/types";

const rejectedKeys = [
  "loading",
  "error",
  "advanced",
  "edit",
  "header",
  "show",
  "updatedAt",
  "updatedBy",
  "orgId",
  "invocationCount",
  "creator",
  "createdAt",
  "activeCount",
  "pendingStatus",
  "completedStatus",
  "tab",
  "checklistFieldSettings",
  "builderComplete",
  "goToLastRoom"
];

const getAllSortedUsers = (state: AppState) => {
  const allUsers = R.values(state.users.byId).filter(
    user => user.disabled !== true && user.orgRole !== "contact"
  );
  const sortedUsers = [...allUsers].sort((a, b) => {
    const nameA = a.displayName.toLowerCase();
    const nameB = b.displayName.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  const allSortedUsers = sortedUsers.map(user => user.uid);
  return allSortedUsers;
};

function* createWorkflow({ payload }: Action) {
  try {
    const result = yield call(workflow.createWorkflow, {
      ...R.omit(rejectedKeys, payload.workflow),
      reminder: (payload.workflow.reminder || []).map(reminder => {
        return {
          ...reminder,
          message: formatMentions(reminder.message)
        };
      })
    });

    if (payload.modal) {
      yield put({
        type: atypes.CREATE_WORKFLOW_SUCCESS,
        payload: result
      });

      yield delay(1800);

      yield put({
        type: atypes.CLEAR_LAST_CREATED_WORKFLOW,
        payload: {}
      });
    } else {
      yield put({
        type: atypes.CREATE_INLINE_WORKFLOW_SUCCESS,
        payload: result
      });
    }

    yield put(workflowActions.setBuilderComplete(true));

    // Show manage view after successful creation of workflow
    yield put(showView("manage"));
    yield put(workflowActions.setWorkflow({}));
  } catch (e) {
    if (payload.modal) {
      yield put({
        type: atypes.CREATE_WORKFLOW_FAILURE,
        payload: e
      });
    } else {
      yield put({
        type: atypes.CREATE_INLINE_WORKFLOW_FAILURE,
        payload: e
      });
    }

    toast.error(`Error creating process templates`);
  }
}

function* watchCreateWorkflow(): any {
  yield takeEvery(atypes.CREATE_WORKFLOW_REQUEST, createWorkflow);
}

function* loadWorkflow({ payload }: Action) {
  try {
    const result = yield call(workflow.getWorkflow, payload.id);
    yield put({ type: atypes.LOAD_WORKFLOW_SUCCESS, payload: result });
  } catch (e) {
    yield put({ type: atypes.LOAD_WORKFLOW_FAILURE, payload: e });
  }
}

function* watchLoadWorkflow(): any {
  yield takeEvery(atypes.LOAD_WORKFLOW_REQUEST, loadWorkflow);
}

function* editWorkflow({ payload }: Action): any {
  try {
    const resolveMentionsInFields = payload.current.checklistFields.map(
      field => {
        if (field.type !== "approval") return field;

        let newSettings: ApprovalFieldSettingsV2 = JSON.parse(field.settings);

        pathsWithMentions.forEach(path => {
          newSettings = R.assocPath(
            path,
            // $FlowFixMe
            formatMentions(R.path(path, newSettings)),
            newSettings
          );
        });

        return { ...field, settings: JSON.stringify(newSettings) };
      }
    );

    const result = yield call(workflow.editWorkflow, {
      ...R.omit(rejectedKeys, payload.current),
      checklistFields: resolveMentionsInFields,
      reminder: (payload.current.reminder || []).map(reminder => {
        return {
          ...reminder,
          message: formatMentions(reminder.message)
        };
      })
    });

    yield put(workflowActions.setBuilderComplete(true));

    // Happens on successful creation of process template
    yield put(showView("manage"));
    yield put(workflowActions.setWorkflow({}));

    toast.success(`Edit process success`);
    yield put({ type: atypes.EDIT_WORKFLOW_SUCCESS, payload: result });
  } catch (e) {
    console.log(e);
    toast.error(`Error editing process`);
    yield put({ type: atypes.EDIT_WORKFLOW_FAILURE, payload: e });
  }
}

function* watchEditWorkflow(): any {
  yield takeEvery(atypes.EDIT_WORKFLOW, editWorkflow);
}

function* deleteWorkflow({ payload }: Action) {
  try {
    const workflowId = payload.workflow;

    yield call(workflow.deleteWorkflow, workflowId);
    yield put({
      type: atypes.DELETE_WORKFLOW_SUCCESS,
      payload: { workflow: payload.workflow }
    });
    toast.success("Deleting process (Cancelling all conversations)");
  } catch (e) {
    toast.error(`Error deleting process`);
    yield put({ type: atypes.DELETE_WORKFLOW_FAILURE, payload: e });
  }
}

function* WatchDeleteWorkflow(): any {
  yield takeEvery(atypes.DELETE_WORKFLOW_REQUEST, deleteWorkflow);
}

function* getWorkflowInstances({ payload }: Action): any {
  const abortController = new AbortController();

  try {
    const id = payload.id || null;

    if (id) {
      const page = parseInt(payload.page || 1, 10);
      const instances = getWorkflowInstances(yield select(getAppState));
      const checklists = (yield select(getAppState)).checklist.fields.byId;

      // Only when a process has a checklist, wait for the checklist
      // data to be fetched to show it in the manage view table
      if (!R.isEmpty(checklists) && !R.isNil(checklists)) {
        yield put({
          type: atypes.GET_PRINCIPAL_CHECKLIST_REQUEST,
          payload: {
            workflow: id
          }
        });

        yield take(atypes.GET_PRINCIPAL_CHECKLIST_SUCCESS);
      }

      // If the user is loading with page number greater than one
      // but has no instances reset page number
      if (page === 1) {
        yield put({
          type: atypes.CLEAR_WORKFLOW_INSTANCES,
          payload: {}
        });
      }

      if (instances.length === 0 && page > 1) {
        yield put({
          type: atypes.CLEAR_WORKFLOW_INSTANCES,
          payload: {}
        });

        yield put({
          type: atypes.SET_PROCESS_REQUEST,
          meta: {
            query: {
              id,
              ...payload,
              page: 1
            }
          }
        });
      } else {
        const workflows = yield call(workflow.getProcessInstancesAsync, {
          id,
          signal: abortController.signal
        });

        const { principalChecklist } = (yield select(getAppState)).workflow;

        const workflowsWithFormattedFieldValues =
          getWorkflowsWithFormattedValues(workflows, principalChecklist);

        if (page > 1) {
          yield put({
            type: atypes.PAGINATE_WORKFLOW_INSTANCES_SUCCESS,
            payload: {
              workflows: workflowsWithFormattedFieldValues
            }
          });
        } else {
          yield put({
            type: atypes.GET_WORKFLOW_INSTANCES_SUCCESS,
            payload: {
              workflows: workflowsWithFormattedFieldValues
            }
          });
        }

        yield put({
          type: atypes.GET_PROCESS_INSTANCE_COUNT_SUCCESS,
          payload: {
            totalCount: 0
          }
        });
      }
    } else {
      yield put({
        type: atypes.CLEAR_PROCESS_ROW_SELECTION,
        payload: {}
      });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: atypes.GET_WORKFLOW_INSTANCES_FAILURE, payload: e });
    toast.error(`Error fetching process instances displaying old value`);
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* watchGetWorkflowInstanes(): any {
  yield takeLatest(atypes.SET_PROCESS_SUCCESS, getWorkflowInstances);
}

function* updateWorkflowInstanceCurrentVersion({ payload }: Action): any {
  const { chatroomId, seqNo } = payload;
  try {
    yield call(chatroom.updateCurrentVersion, chatroomId);

    let instances = R.clone(yield select(selectors.getWorkflowInstances));
    const principalChecklist = yield select(selectors.getPrincipalChecklist);

    const prevCurrentVersionIndex = instances.findIndex(
      instance => instance.seqNo === seqNo && instance.currentVersion
    );

    if (prevCurrentVersionIndex === -1) {
      throw new Error();
    }

    const prevCurrentVersion = instances[prevCurrentVersionIndex];
    prevCurrentVersion.currentVersion = false;
    prevCurrentVersion.derivedTitle = `${principalChecklist.title} #${prevCurrentVersion.autoNo}: ${prevCurrentVersion.title}`;

    const tempNewCurrentVersion = instances[payload.index];
    tempNewCurrentVersion.currentVersion = true;
    tempNewCurrentVersion.derivedTitle = `${principalChecklist.title} #${tempNewCurrentVersion.autoNo}C: ${tempNewCurrentVersion.title}`;

    instances[prevCurrentVersionIndex] = tempNewCurrentVersion;
    instances[payload.index] = prevCurrentVersion;

    yield put({
      type: atypes.UPDATE_WORKFLOW_INSTANCE_CURRENT_VERSION_SUCCESS,
      payload: instances
    });

    toast.success("Updated current version");
  } catch (error) {
    toast.error("Could not update current version");
  }
}

function* watchUpdateWorkflowInstanceCurrentVersion(): any {
  yield takeEvery(
    atypes.UPDATE_WORKFLOW_INSTANCE_CURRENT_VERSION_REQUEST,
    updateWorkflowInstanceCurrentVersion
  );
}

function* getNextSeqNo({ payload }: Action): any {
  try {
    const response = yield call(workflow.getNextSeqNo, payload);

    yield put({
      type: atypes.GET_NEXT_SEQ_NO_REQUEST_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put({
      type: atypes.SET_NEW_CONVERSATION_ATTRIBUTES,
      payload: {
        value: {
          error: "Error fetching next sequence number"
        }
      }
    });

    yield put({
      type: atypes.GET_NEXT_SEQ_NO_REQUEST_FAILURE,
      payload: e
    });
  }
}

function* watchGetNextSeqNo(): any {
  yield takeEvery(atypes.GET_NEXT_SEQ_NO_REQUEST, getNextSeqNo);
}

function* setRecentWorkflow({ payload }: Action): any {
  try {
    const uid = yield select(getCurrentUserId);
    const recent = yield select(getRecentWorkflows);

    yield call(workflow.setRecentWorkflow, {
      workflows: [...recent.toJS(), payload.workflow],
      uid
    });
    yield put({
      type: atypes.SET_RECENT_WORKFLOW_SUCCESS,
      payload: {
        workflows: [...recent.toJS(), payload.workflow]
      }
    });
  } catch (e) {
    yield put({
      type: atypes.SET_RECENT_WORKFLOW_FAILURE,
      payload: { e }
    });
  }
}

function* watchSetRecentWorkflow(): any {
  yield takeEvery(atypes.SET_RECENT_WORKFLOW_REQUEST, setRecentWorkflow);
}

function* searchWorkflow({ payload }: Action): any {
  try {
    const workflows = getWorkflowTemplates(yield select(getAppState));
    const { searchString } = payload;

    const result = R.map(
      R.prop("id"),
      R.filter(
        w => R.includes(R.toLower(searchString), R.toLower(w.title)),
        workflows
      )
    );

    switch ((payload.settings || {}).searchType) {
      case "uniqueWorkflow":
        yield put({
          type: atypes.SEARCH_UNIQUE_WORKFLOW_SUCCESS,
          payload: { column: payload?.settings?.column, result }
        });
        break;
      default:
        yield put({
          type: atypes.SEARCH_WORKFLOW_SUCCESS,
          payload: { result }
        });
    }
  } catch (e) {
    yield put({
      type: atypes.SEARCH_WORKFLOW_FAILURE,
      payload: { e }
    });
  }
}

function* watchSearchWorkflow(): any {
  yield throttle(800, atypes.SEARCH_WORKFLOW_REQUEST, searchWorkflow);
}

function* resetWorkflowSearch(): any {
  const workflows = getWorkflowTemplates(yield select(getAppState));
  yield put({
    type: atypes.RESET_WORKFLOW_SEARCH_SUCCESS,
    payload: {
      workflows: R.map(R.prop("id"), workflows)
    }
  });
}

function* watchResetWorkflowSearch(): any {
  yield takeEvery(atypes.RESET_WORKFLOW_SEARCH_REQUEST, resetWorkflowSearch);
}

function* syncWorkflows(): any {
  yield delay(2000);
  const orgId = yield select(getLastOrg);

  try {
    const channel = rsf.firestore.channel(`orgs/${orgId}/processTemplates`);

    while (true) {
      const snapshot = yield take(channel);
      const workflows = [];
      const removedWorkflows = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const { doc, type } of snapshot.docChanges()) {
        if (type === "added" || type === "modified") {
          const data = doc.data();
          const { createdAt, updatedAt } = data;

          // Remove deleted workflows
          if (data.deleted) {
            removedWorkflows.push(data.id);
            continue;
          }

          let formattedDates = {
            createdAt,
            updatedAt
          };

          // DO NOT REMOVE THIS CODE WITHOUT CONFIRMATION FROM THE BACKEND TEAM
          // There is an issue in a firestore doc where these dates are not in the expected format,
          // so this code accomodates the issue
          // Expected:
          // {seconds: 1674035343, nanoseconds: 873000000}
          // Actual:
          // {epochSecond: 1674035409, nano: 959000000}
          try {
            formattedDates.createdAt = createdAt ? createdAt.toDate() : null;
          } catch (error) {
            console.error(error);
            formattedDates.createdAt = null;
          }

          try {
            formattedDates.updatedAt = updatedAt ? updatedAt.toDate() : null;
          } catch (error) {
            console.error(error);
            formattedDates.updatedAt = null;
          }

          workflows.push({
            ...data,
            createdAt: formattedDates.createdAt,
            updatedAt: formattedDates.updatedAt
          });
        } else if (type === "removed") {
          const data = doc.data();
          removedWorkflows.push(data.id);
        }
      }

      const { byId } = (yield select(getAppState)).workflow;
      const newWorkflowTemplates = R.values(byId || []);

      // Removes duplicate when making changes in process tampletes
      const removeDuplicate = R.unionWith(
        R.eqBy(R.prop("id")),
        workflows,
        newWorkflowTemplates
      );

      let sortedWorkflows = R.sort((a, b) => a.id - b.id, [...removeDuplicate]);

      // Checks if process already exists
      const isWrokflowExists = R.includes(
        workflows[0]?.address,
        newWorkflowTemplates.map(x => x.address)
      );

      // if process already exists then assign color of that process to
      // newly created conversation from that process
      if (isWrokflowExists) {
        const newProcess = R.filter(
          e => R.equals(workflows[0].address, e.address),
          newWorkflowTemplates
        );

        const currentCreatedProcess = R.filter(
          e => R.equals(workflows[0].address, e.address),
          sortedWorkflows
        );

        sortedWorkflows = sortedWorkflows.map(el => {
          return R.equals(el, currentCreatedProcess[0])
            ? { ...el, color: newProcess[0].color }
            : el;
        });
      } else {
        const lastWorkflowColorIndex = workflowColors.length - 1;
        let colorIndex = 0;

        for (let i = 0; i < sortedWorkflows.length; i++) {
          if (colorIndex === lastWorkflowColorIndex) {
            sortedWorkflows[i] = {
              ...sortedWorkflows[i],
              color: workflowColors[lastWorkflowColorIndex]
            };
            colorIndex = 0;
          } else {
            sortedWorkflows[i] = {
              ...sortedWorkflows[i],
              color: workflowColors[colorIndex]
            };
            colorIndex++;
          }
        }
      }

      if (workflows.length > 0) {
        yield put({
          type: atypes.SYNC_WORKFLOWS_SUCCESS,
          payload: {
            workflows: sortedWorkflows
          }
        });
      }

      if (removedWorkflows.length > 0) {
        yield put({
          type: atypes.REMOVE_WORKFLOW_SUCCESS,
          payload: {
            workflows: removedWorkflows
          }
        });
      }
    }
  } catch (error) {
    console.log("Failed to sync workflows", error);
    yield put({
      type: atypes.SYNC_WORKFLOWS_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchSyncWorkflows(): any {
  yield takeEvery(atypes.API_AUTH_SUCCESS, syncWorkflows);
}

function* watchSrwSyncWorkflows(): any {
  yield takeEvery(atypes.SRW_SERVER_AUTH_SUCCESS, syncWorkflows);
}

function* mostUsedWorkflow(): any {
  try {
    const { byId } = (yield select(getAppState)).workflow;
    const orgId = yield select(getLastOrg);
    const newWorkflowTemplates = R.values(byId || []);

    // Mostly used process list for Willburt org
    if (orgId == 141) {
      yield put({
        type: atypes.MOST_USED_WORKFLOW,
        payload: {
          workflows: [455, 457, 458, 579, 568, 462, 728, 463, 702]
        }
      });
    } else {
      yield put({
        type: atypes.MOST_USED_WORKFLOW,
        payload: {
          workflows: R.take(
            6,
            R.map(
              w => parseInt(w.id, 10),
              R.sortWith([R.descend(R.prop("invocationCount"))])(
                R.filter(
                  workflow =>
                    !workflow?.settings?.hideProcessInNew && !workflow.deleted,
                  newWorkflowTemplates
                )
              )
            )
          )
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchMostUsedWorkflow(): any {
  yield takeLatest(
    [atypes.SYNC_WORKFLOWS_SUCCESS, atypes.DELETE_WORKFLOW_SUCCESS],
    mostUsedWorkflow
  );
}

function* showProcess({ meta }: Action): any {
  try {
    const { uid } = yield select(getUser);
    const query = (meta || {}).query || {};
    const sort = query.sort || [];

    if (!uid) {
      yield put({ type: atypes.SIGN_IN });
      yield put({
        type: atypes.SET_REQUESTED_PAGE,
        payload: {
          page: "process",
          query: {
            ...query,
            sort: R.type(sort) === "String" ? [sort] : sort
          }
        }
      });
    } else {
      yield put({
        type: atypes.SET_PROCESS_SUCCESS,
        payload: {
          ...query,
          sort: R.type(sort) === "String" ? [sort] : sort
        }
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SET_PROCESS_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchShowProcess(): any {
  yield takeLatest(atypes.SET_PROCESS_REQUEST, showProcess);
}

/**
 * Redirecting /process to /manage
 */
function* watchSetProcessAlias(): any {
  yield takeEvery(atypes.SET_PROCESS_ALIAS_REQUEST, showProcess);
}

function* uniqueProcessInstanceValues({ payload }: Action): any {
  try {
    const members = getAllUserIds(yield select(getAppState)).toJS();
    const instances = getWorkflowInstances(yield select(getAppState));
    const fields =
      (yield select(getAppState)).workflow.principalChecklist.fields || [];

    // All process related statuses
    const statuses = getWorkflowStatus(
      yield select(getAppState),
      payload.workflow
    );

    const { allIds } = (yield select(getAppState)).users;

    const allUsers = getAllSortedUsers(yield select(getAppState));

    const uniqueFieldValues = fields
      .filter(f => f.label && !R.includes(f.type, omitedFields))
      .map(field => {
        let value = [];
        switch (field.type) {
          case "select":
            value = R.uniq(
              R.flatten(instances.map(instance => instance[field.id]))
            ).filter(val => val);
            break;

          case "conversation":
          case "chatPickList":
          case "workflow":
          case "task":
          case "group":
          case "childConversation":
            value = R.uniq(
              R.flatten(
                instances.map(instance =>
                  (instance[field.id] || []).map(
                    conversation => conversation.id
                  )
                )
              )
            ).filter(val => val);
            break;

          case "link":
            value = R.uniq(
              R.flatten(
                instances.map(instance => instance[field.id]?.result || [])
              )
            ).filter(val => val);
            break;

          case "form":
            value = R.uniq(
              R.flatten(
                instances.map(instance =>
                  (instance[field.id] || []).map(form => form.templateId)
                )
              )
            ).filter(val => val);
            break;

          case "user":
            value = allUsers;
            break;

          default:
            value = [];
        }

        return { id: field.id, value };
      });
    const uniqueValuesByField = uniqueFieldValues.reduce(
      (acc, value) => ({ ...acc, [value.id]: value.value }),
      {}
    );

    // Priority, status, creator, owner and participants require unfiltered options
    const uniqueValues = {
      ...uniqueValuesByField,
      priority: priority.default,
      status: Object.keys(statuses).map(status => statuses[status].id),
      owner: R.uniq(
        instances
          .filter(instance => instance.owner)
          .map(instance => instance.owner)
      ),
      creator: allIds,
      members: allIds
    };

    yield put({
      type: atypes.GET_PROCESS_UNIQUE_INSTANCE_VALUES_SUCCESS,
      payload: {
        uniqueValues: { ...uniqueValues, members }
      }
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: atypes.GET_PROCESS_UNIQUE_INSTANCE_VALUES_FAILURE,
      payload: { error }
    });
  }
}

function* watchUniqueProcessInstanceValues(): any {
  yield takeEvery(
    atypes.GET_WORKFLOW_INSTANCES_SUCCESS,
    uniqueProcessInstanceValues
  );
}

function getUniqueEmbeddedFieldValues(
  instances: Array<WorkflowInstances>,
  field: string
) {
  const isEmbeddedField = field.includes("-");
  return R.uniq(
    R.flatten(
      instances.map(instance => {
        if (isEmbeddedField) {
          if (instance[`${field}-original`] || instance[field]) {
            return (instance[`${field}-original`] || instance[field]).map(
              embeddedLinkedField => embeddedLinkedField?.result || []
            );
          } else {
            return [];
          }
        } else {
          return (
            (instance[`${field}-originla`] || instance[field])?.result || []
          );
        }
      })
    )
  );
}

function* getFieldUniqueValues({ payload }: Action): any {
  try {
    const instances = R.values(
      (yield select(getAppState)).workflow.instancesById
    );

    const field = payload.columnId;
    let value = [];

    const allUsers = getAllSortedUsers(yield select(getAppState));

    switch (payload.type) {
      case "select":
        value = R.uniq(
          R.flatten(
            instances.map(
              instance => instance[`${field}-original`] || instance[field]
            )
          )
        ).filter(val => val);
        break;

      case "conversation":
      case "chatPickList":
      case "workflow":
      case "task":
      case "group":
      case "childConversation":
        value = R.uniq(
          R.flatten(
            instances.map(instance =>
              (instance[`${field}-original`] || instance[field] || []).map(
                conversation =>
                  Array.isArray(conversation)
                    ? conversation.map(
                        singleConversation => singleConversation?.id
                      )
                    : conversation?.id
              )
            )
          )
        ).filter(val => val);
        break;

      case "link":
        value = getUniqueEmbeddedFieldValues(instances, field);
        break;

      case "form":
        value = R.uniq(
          R.flatten(
            instances.map(instance =>
              (instance[`${field}-original`] || instance[field] || []).map(
                form =>
                  Array.isArray(form)
                    ? form.map(singleForm => singleForm?.templateId)
                    : form?.templateId
              )
            )
          )
        ).filter(val => val);
        break;

      case "user":
      case "owner":
        value = allUsers;
        break;

      default:
        value = [];
    }

    yield put({
      type: atypes.SET_FIELD_UNIQUE_VALUES,
      payload: {
        id: field,
        value
      }
    });
  } catch (error) {
    console.error(error);
  }
}

function* watchGetFieldUniqueValues(): any {
  yield takeEvery(atypes.GET_FIELD_UNIQUE_VALUES, getFieldUniqueValues);
}

/**
 * Converts a value to a string suitable for inclusion in a CSV file.
 * @param {any} fieldValue - The value to convert.
 * @returns {string} - The converted string.
 */
const valueToCSVField = (fieldValue: any) => {
  if (R.type(fieldValue) === "Array") {
    // Replace all occurrences of double quotes with two consecutive
    // double quotes for each element in the array
    const escapedArrayElements = fieldValue.map((value = "") =>
      `${value}`.replace(/"/g, '""')
    );
    return `"${escapedArrayElements.join(", ")}"`;
  }

  // Replace all occurrences of double quotes with two consecutive
  // double quotes. This is necessary because CSV files use double
  // quotes to enclose fields that contain commas or double quotes.
  const escapedValue = `${fieldValue || ""}`.replace(/"/g, '""');

  return `"${escapedValue}"`;
};

/**
 * Converts a value to a string suitable for inclusion in an XLSX file.
 * @param {any} fieldValue - The value to convert.
 * @return {string} - The value as a string, with necessary escaping applied.
 */
const valueToXLSXField = (fieldValue: any) => {
  if (R.type(fieldValue) === "Array") {
    return fieldValue.map(valueToXLSXField).join(", ");
  }

  if (R.isNil(fieldValue)) {
    return "";
  }

  // Convert the value to a string and remove tab character to prevent
  // formatting issues in the XLSX file
  return `${fieldValue}`.replace(/\t/g, "");
};

/**
 * Converts an array of rows to an XLSX file
 * @param {Array} rows The array of rows to convert
 * @return {Buffer} The XLSX file data as a buffer
 */
const rowsToXLSX = rows => {
  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Add the rows to the workbook as a sheet
  const ws = XLSX.utils.aoa_to_sheet(rows);
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Generate the XLSX file data
  const xlsxData = XLSX.write(wb, { type: "array", bookType: "xlsx" });

  // Convert the XLSX data to a buffer
  const xlsxBuffer = new Uint8Array(xlsxData).buffer;

  // Return the XLSX buffer
  return xlsxBuffer;
};

/**
 * Converts the user values in table to a format suitable for exporting
 * @param {string | UnifizeUser | Array<string | UnifizeUser>} value - user details or UID
 * @param {UsersById} usersById - details of all the users present in redux store
 * @returns {string} - The resolved value to be shown in csv.
 */
const resolveUserValueForExport = (
  value: string | UnifizeUser | Array<string | UnifizeUser>,
  usersById: UsersById
) => {
  if (Array.isArray(value)) {
    // if it's an embedded field go a level deeper
    const resolvedUsers = value.map(nestedUser => {
      const nestedUserId =
        typeof nestedUser === "string" ? nestedUser : nestedUser?.uid;
      return `${usersById[nestedUserId]?.displayName || ""} <${
        usersById[nestedUserId]?.email || ""
      }>`;
    });
    return resolvedUsers;
  }
  const userId = typeof value === "string" ? value : value?.uid;
  return `${usersById[userId]?.displayName || ""} <${
    usersById[userId]?.email || ""
  }>`;
};

const resolveValueForExport = (
  fieldValue: any,
  type: string,
  settings: string,
  format: "csv" | "xlsx",
  usersById: UsersById,
  statusesById: StatusById,
  workflowsById: WorkflowById
) => {
  let resolvedValue;

  switch (type) {
    case "select":
      const multiple = JSON.parse(settings || "{}").multiple || false;
      resolvedValue =
        !multiple && R.type(fieldValue) === "Array" && fieldValue.length
          ? fieldValue[0]
          : fieldValue || [];
      break;
    case "creator":
    case "owner":
      resolvedValue = usersById[fieldValue || ""]?.displayName || "";
      break;
    case "completedBy":
      // Once Bug #4323 is fixed, remove this & move case next to owner
      resolvedValue =
        usersById[(fieldValue || "").replace("user/", "")]?.displayName || "";
      break;
    case "members":
    case "user":
      resolvedValue = (fieldValue || []).map(user => {
        const resolvedUserValue = resolveUserValueForExport(user, usersById);
        return resolvedUserValue;
      });
      break;
    case "conversation":
    case "chatPickList":
    case "workflow":
    case "task":
    case "group":
    case "childConversation":
    case "revision":
    case "parent":
      resolvedValue = (fieldValue || []).map(room => {
        if (!room) {
          return "No title";
        }
        // Exclude autoNo & processTitle if it's configured in the
        // process settings
        if (
          room.templateId &&
          workflowsById[room.templateId]?.settings?.hideAutoNumber
        ) {
          return `${room.title || "No title"}`;
        }

        return `${room.processTitle} ${room.autoNo}: ${
          room.title || "No title"
        }`;
      });
      break;
    case "form":
      resolvedValue = (fieldValue || []).map(form =>
        form ? `${form.templateTitle} ${form.address}` : "No value"
      );
      break;
    case "link":
      {
        const chatroomIds = fieldValue?.result || [];
        const chatrooms = fieldValue?.entities?.chatrooms || {};

        resolvedValue = chatroomIds.map(id => {
          const room = chatrooms[id].chatroom;

          // Exclude autoNo & processTitle if it's configured in the
          // process settings
          if (
            room.templateId &&
            workflowsById[room.templateId]?.settings?.hideAutoNumber
          ) {
            return `${room.title || "No title"}`;
          }

          return `${room.processTitle} ${room.autoNo}: ${
            room.title || "No title"
          }`;
        });
      }
      break;
    case "status":
      {
        if (!fieldValue) {
          return "";
        }
        if (fieldValue > 0) {
          // $FlowFixMe
          resolvedValue = statusesById.get(`${fieldValue}`)?.get("title") || "";
          break;
        }

        resolvedValue = capitalize(defaultStatus[fieldValue].text);
      }
      break;
    case "file":
    case "pdf": {
      if (
        R.type(fieldValue) === "Array" &&
        R.type(fieldValue[0]) === "Object"
      ) {
        resolvedValue = fieldValue.map(file => (file ? file.originalName : ""));
        break;
      }

      resolvedValue = [];
      break;
    }
    case "text":
      resolvedValue = `${fieldValue || ""}`.replace(/\n/g, " ");
      break;
    default:
      resolvedValue = fieldValue || "";
  }
  return format === "xlsx"
    ? valueToXLSXField(resolvedValue)
    : valueToCSVField(resolvedValue);
};

function* downloadProcessInstance({ payload }: Action): any {
  const { rows, workflow: workflowId, format, includeFiles } = payload;
  if (format === "files" || format === "json") {
    try {
      const response = yield call(
        workflow.initiateExportJob,
        Number(workflowId),
        format === "files" ? "xlsx" : format,
        includeFiles
      );

      if (response && response["job-id"]) {
        toast.success(
          "A download link will be emailed to you in a couple of mintues"
        );

        yield put({
          type: atypes.GET_PROCESS_INSTANCE_FILE_SUCCESS,
          payload: {}
        });
      }
    } catch (error) {
      toast.error("Could not start download");
      yield put({
        type: atypes.GET_PROCESS_INSTANCE_FILE_FAILURE,
        payload: { error }
      });
    }
  } else {
    try {
      const workflowTitle =
        getWorkflowTitle(yield select(getAppState), workflowId) || "Process";
      const headers = payload.headers
        .map(header => {
          // Replace seqNo with autoNo
          if (header.id === "seqNo") {
            return {
              ...header,
              id: "autoNo",
              column: {
                ...header.column,
                columnDef: {
                  ...header.column.columnDef,
                  type: "autoNo",
                  header: "Auto no."
                }
              }
            };
          }

          return header;
        })
        .filter(header => header.id !== "approval");
      const headerLabels = headers.map(header => {
        const headerLabel = header.column.columnDef.header;
        // wrap the header label in quotes if it contains (,)
        if (headerLabel.includes(",")) {
          return `"${headerLabel}"`;
        }
        return headerLabel;
      });
      const usersById = (yield select(getAppState)).users.byId;
      const statusesById = (yield select(getAppState)).statuses.byId;
      const workflowsById = (yield select(getAppState)).workflow.byId;

      // Convert the rows array into an array of row arrays
      const rowArrays = rows
        .filter(row => row.original.currentVersion)
        .map(row => {
          return headers.map(header => {
            return resolveValueForExport(
              header.id === "autoNo"
                ? row.original.autoNo
                : header.id === "dueDate"
                  ? moment(row.getValue(header.id)).format("YYYY-MM-DD")
                  : row.getValue(header.id),
              header.column.columnDef.type || header.id,
              header.column.columnDef.settings,
              format,
              usersById,
              statusesById,
              workflowsById
            );
          });
        });
      const tableArr = [headerLabels, ...rowArrays];

      // Convert the array of rows to a CSV string or XLSX data
      const outputFile =
        format === "xlsx"
          ? rowsToXLSX(tableArr)
          : tableArr.map(row => row.join(",")).join("\n");

      // Create a download link and initiate the download of the file
      const downloadLink = document.createElement("a");
      const fileBlob = new Blob([outputFile], {
        fileType:
          format === "xlsx"
            ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            : "text/csv;charset=utf-8;"
      });
      downloadLink.href = URL.createObjectURL(fileBlob);
      downloadLink.setAttribute("download", `${workflowTitle}.${format}`);
      downloadLink.click();

      yield put({
        type: atypes.GET_PROCESS_INSTANCE_FILE_SUCCESS,
        payload: {}
      });
    } catch (error) {
      console.error(error.message, error);
      toast.error("Error downloading process.");
      yield put({
        type: atypes.GET_PROCESS_INSTANCE_FILE_FAILURE,
        payload: { error }
      });
    }
  }
}

function* watchDownloadProcessInstance(): any {
  yield takeEvery(
    atypes.GET_PROCESS_INSTANCE_FILE_REQUEST,
    downloadProcessInstance
  );
}

function* refetchWorkflowInstances(): any {
  const { instanceFilter } = (yield select(getAppState)).workflow;
  yield put({
    type: atypes.SET_PROCESS_REQUEST,
    payload: {},
    meta: {
      query: instanceFilter
    }
  });
}

function* watchRefetchWorkflowInstances(): any {
  yield takeEvery(atypes.REFETCH_WORKFLOW_INSTANCES, refetchWorkflowInstances);
}

function* getPrincipalChecklist({ payload }: Action): any {
  try {
    const checklist = yield call(workflow.getPrincipalChecklist, payload);
    yield put({
      type: atypes.GET_PRINCIPAL_CHECKLIST_SUCCESS,
      payload: {
        checklist
      }
    });
  } catch (error) {
    console.error("Principal checklist fail:", error);
    yield put({
      type: atypes.GET_PRINCIPAL_CHECKLIST_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetPrincipalChecklist(): any {
  yield takeEvery(
    atypes.GET_PRINCIPAL_CHECKLIST_REQUEST,
    getPrincipalChecklist
  );
}

function* saveCustomView({ payload }: Action): any {
  try {
    const orgId = yield select(getLastOrg);
    const uid = yield select(getCurrentUserId);

    const reportId = getInstanceReportId(yield select(getAppState));
    const deepFilter = getAllRecords(yield select(getAppState));

    if (reportId) {
      const report = getReport(yield select(getAppState), reportId);
      report["filters"] = encodeColumnId(report.filters || {});
      report["filters"]["deepFilter"] =
        Object.keys(deepFilter).filter(columnId => {
          if (deepFilter[columnId]) {
            return columnId;
          }
        }) || [];

      yield put({
        type: atypes.EDIT_REPORT_REQUEST,
        payload: {
          ...report,
          settings: {
            ...report.settings,
            columns: payload.columns
          },
          showToast: payload.showToast
        }
      });
    } else {
      yield call(workflow.saveCustomizedView, {
        orgId,
        columns: payload.columns,
        templateId: payload.id,
        uid
      });
    }
  } catch (e) {
    yield put({
      type: atypes.SAVE_CUSTOM_PROCESS_COLUMN_FAILURE,
      payload: { e }
    });
  }
}

function* watchSaveCustomView(): any {
  yield takeEvery(atypes.REORDER_PROCESS_COLUMN, saveCustomView);
}

function* syncCustomView({ payload }: Action): any {
  const { last: orgId } = payload;
  const { uid } = yield select(getUser);

  const channel = rsf.firestore.channel(
    connection().collection(
      `userData/${uid}/appData/settings/${orgId}/processTable/columns`
    )
  );

  try {
    while (true) {
      const snapshot = yield take(channel);
      let processColumn = {};
      for (const { doc } of snapshot.docChanges()) {
        const { id } = doc;
        const { columns } = doc.data();
        processColumn[id] = columns;
      }

      yield put({
        type: atypes.SYNC_CUSTOM_PROCESS_COLUMN_SUCCESS,
        payload: {
          processColumn
        }
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SYNC_CUSTOM_PROCESS_COLUMN_FAILURE,
      payload: { error }
    });
  }
}

function* watchSyncCustomView(): any {
  yield takeEvery(atypes.SYNC_CUSTOM_PROCESS_COLUMN_REQUEST, syncCustomView);
}

function* bulkUpdate({ payload }: BulkUpdateProcessAction): any {
  const oldValues = [];
  const oldSelectedInstanceValues = {};
  const chatroomsById = (yield select(getAppState)).chatRooms.byId;
  const chatrooms = (yield select(getAppState)).workflow.selectedRows;
  const autoNos = chatrooms.map(chatroom => chatroomsById[chatroom]?.autoNo);
  try {
    const attributes = R.keys(payload.attrs);

    const instances = getWorkflowInstancesArr(yield select(getAppState));

    // Get old chatroom metadata for the selected chatroom
    // So that it can be reverted in-case API call fails
    for (const chatroom of chatrooms) {
      // This done seperately from the for process table and chatroom metadata
      // for making it easier to merge the state in reducer
      for (const [index, row] of instances.entries()) {
        if (chatroom === row.chatroomId) {
          oldSelectedInstanceValues[index] = row;
        }
      }
      for (const attribute of attributes) {
        oldValues.push({
          id: `${chatroom}`,
          value: {
            [attribute]: getChatRoom(yield select(getAppState), `${chatroom}`)[
              attribute
            ]
          }
        });
      }
    }

    if (!payload.options) {
      yield put({
        type: atypes.BULK_UPDATE_PROCESS_OPTIMISTIC,
        payload: {
          chatrooms,
          autoNos,
          value: payload.attrs
        }
      });
    }

    const filter = getInstanceFilter(yield select(getAppState));
    const id = filter.id || null;

    // Check if some process is selected
    // and some rows are selected
    if (id && chatrooms.length > 0) {
      // $FlowFixMe
      yield call(workflow.bulkUpdate, {
        chatrooms,
        templateId: parseInt(id, 10),
        ...payload
      });

      yield put({
        type: atypes.BULK_UPDATE_PROCESS_SUCCESS,
        payload: {}
      });
      toast.success(`Bulk update succeeded`);

      // Refresh the results
      yield put(workflowActions.clearWorkflowInstances());

      const filter = yield select(getWorkflowInstanceFilter);
      yield put(workflowActions.setWorkflow({ query: filter }));
    }
  } catch (error) {
    console.error(error);
    toast.error(`Unable to perform bulk update`);
    yield put({
      type: atypes.BULK_UPDATE_PROCESS_FAILURE,
      payload: { oldValues, oldSelectedInstanceValues, autoNos }
    });
  } finally {
    // Remove selection after bulk update is complete
    yield put({
      type: atypes.CLEAR_PROCESS_ROW_SELECTION,
      payload: {}
    });
  }
}

function* watchBulkUpdate(): any {
  yield takeEvery(atypes.BULK_UPDATE_PROCESS_REQUEST, bulkUpdate);
}

function* fetchStatusCount({ payload }: Action): any {
  try {
    const statusCount = yield call(workflow.fetchStatusCount, payload.id);
    yield put({
      type: atypes.FETCH_STATUS_COUNT_SUCCESS,
      payload: { statusCount }
    });
  } catch (error) {
    yield put({
      type: atypes.FETCH_STATUS_COUNT_FAILURE,
      payload: { error }
    });
  }
}

function* watchFetchStatusCount(): any {
  yield takeEvery(atypes.FETCH_STATUS_COUNT_REQUEST, fetchStatusCount);
}

// On viewing process, fetch the status count
// When deleting a status, we check if that
// status is on any conversation with this
function* watchViewProcessDetails(): any {
  yield takeEvery(atypes.VIEW_PROCESS_DETAILS, fetchStatusCount);
}

function* searchPrincipalChecklist({ payload }: Action): any {
  try {
    const { fields } = (yield select(getAppState)).workflow.principalChecklist;
    const searchTerm = R.toLower(payload.text || "");

    // Don't show sections and subsections in embedded field dropdown
    const forbiddenFieldTypes = ["section", "subSection"];

    const result = R.map(
      R.prop("id"),
      fields.filter(
        field =>
          !forbiddenFieldTypes.includes(field.type) &&
          R.includes(searchTerm, R.toLower(field.label || ""))
      )
    );

    yield put({
      type: atypes.SEARCH_PRINCIPAL_CHECKLIST_SUCCESS,
      payload: {
        result
      }
    });
  } catch (error) {
    yield put({
      type: atypes.SEARCH_PRINCIPAL_CHECKLIST_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchSearchPrincipalChecklist(): any {
  yield takeLatest(
    atypes.SEARCH_PRINCIPAL_CHECKLIST_REQUEST,
    searchPrincipalChecklist
  );
}

function* getSystemProcess(): any {
  try {
    const {
      payload: { workflows }
    } = yield take(atypes.SYNC_WORKFLOWS_SUCCESS);

    const systemProcess = R.mergeAll(
      R.map(
        w => ({ [w.settings.systemTitle.toLowerCase()]: parseInt(w.id, 10) }),

        R.filter(workflow => workflow?.settings?.systemTitle, workflows)
      )
    );

    yield put({
      type: atypes.SET_SYSTEM_PROCESS,
      payload: systemProcess
    });
  } catch (error) {
    console.log(error);
  }
}

function* watchGetSystemProcess(): any {
  yield takeLatest(atypes.API_AUTH_SUCCESS, getSystemProcess);
}

function* updateChatroomFromManageView({ payload }: Action): any {
  const { parent } = payload.value;
  const app = yield select(getAppState);
  const name = getChatroomTitle(app, parent);
  const seqNo = getSequenceNo(app, parent);
  const processTitle = getProcessTitle(app, parent);
  const type = getChatroomType(app, parent);
  const room = getChatRoom(app, `${payload.roomId}`);
  const { autoNo, templateId } = room;
  const oldValues = app.workflow.instancesById[autoNo];
  const chatroomAttributes = ["owner", "status", "dueDate"];

  const status = payload.value.status || room.status;
  const active = templateId
    ? getActiveStatus(app, `${templateId}`, `${status}`)
    : room.active;

  let heading = name;

  if (type === "workflow") {
    heading = `${processTitle || ""}${seqNo ? ` #${seqNo || ""}` : ""}${
      name ? `: ${name} ` : ""
    }`;
  }

  try {
    if (payload.value.parent) {
      yield put({
        type: atypes.UPDATE_CHATROOM_FROM_MANAGE_VIEW_OPTIMISTIC,
        payload: {
          autoNo,
          values: {
            ...oldValues,
            parentId: payload.value.parent,
            parentTitle: heading
          }
        }
      });
    } else if (
      payload.columnId &&
      R.intersection(Object.keys(payload.value), chatroomAttributes).length > 0
    ) {
      // only run this logic when updating the chatroom attributes like
      // owner, dueDate and status
      const instanceUpdates = [];
      const instancesById = yield select(getWorkflowInstancesById);
      Object.keys(instancesById).forEach(instanceId => {
        const instance = instancesById[`${instanceId}`];
        if (
          instance &&
          R.has(payload.columnId, instance) &&
          (instance[payload.columnId].result || []).includes(
            parseInt(payload.roomId)
          )
        ) {
          const updatedInstance = {
            ...instance,
            [payload.columnId]: {
              ...instance[payload.columnId],
              entities: {
                chatrooms: {
                  ...instance[payload.columnId].entities.chatrooms,
                  [payload.roomId]: {
                    ...instance[payload.columnId]?.entities?.chatrooms?.[
                      payload.roomId
                    ],
                    chatroom: {
                      ...instance[payload.columnId]?.entities?.chatrooms?.[
                        `${payload.roomId}`
                      ]?.chatroom,
                      ...payload.value
                    }
                  }
                }
              }
            }
          };

          instanceUpdates.push(
            put({
              type: atypes.UPDATE_CHATROOM_FROM_MANAGE_VIEW_OPTIMISTIC,
              payload: {
                autoNo: instance.autoNo,
                values: { ...updatedInstance }
              }
            })
          );
        }
      });
      yield all(instanceUpdates);
    } else {
      yield put({
        type: atypes.UPDATE_CHATROOM_FROM_MANAGE_VIEW_OPTIMISTIC,
        payload: {
          autoNo,
          values: { ...oldValues, ...payload.value, active }
        }
      });
    }

    yield call(chatroom.updateAttribute, payload);
    toast.success("Updated conversation");
  } catch (error) {
    console.log(error);
    toast.error("Unable to update conversation");
    yield put({
      type: atypes.UPDATE_CHATROOM_FROM_MANAGE_VIEW_FAILURE,
      payload: {
        autoNo,
        values: oldValues
      }
    });
  }
}

function* watchUpdateChatroomFromManageView(): any {
  yield takeLatest(
    atypes.UPDATE_CHATROOM_FROM_MANAGE_VIEW_REQUEST,
    updateChatroomFromManageView
  );
}

function* updateNestedChatroomFromManageView({ payload }: Action): any {
  const nestedRows = getNestedRows(yield select(getAppState), payload.parentId);
  const oldValues = nestedRows?.[payload.index];

  try {
    const newValue = [
      ...nestedRows.slice(0, payload.index),
      {
        ...oldValues,
        ...payload.value
      },
      ...nestedRows.slice(payload.index + 1)
    ];

    yield put({
      type: atypes.UPDATE_NESTED_CHATROOM_FROM_MANAGE_VIEW_OPTIMISTIC,
      payload: {
        parentId: payload.parentId,
        nestedRows: newValue
      }
    });

    yield call(chatroom.updateAttribute, payload);
    toast.success("Updated conversation");
  } catch (error) {
    toast.error("Unable to update conversation");
    yield put({
      type: atypes.UPDATE_NESTED_CHATROOM_FROM_MANAGE_VIEW_FAILURE,
      payload: {
        parentId: payload.parentId,
        nestedRows
      }
    });
  }
}

function* watchUpdatedNestedChatroomManageView(): any {
  yield takeLatest(
    atypes.UPDATE_NESTED_CHATROOM_FROM_MANAGE_VIEW_REQUEST,
    updateNestedChatroomFromManageView
  );
}

const linkedFieldDeleteHandler = (oldValues, payload) => {
  const chatroomIdToDelete = payload.value.value.originChatroomId;

  if (payload.columnId.includes("-")) {
    const newFieldValue = R.clone(
      oldValues[payload.columnId]?.[payload.embeddedIndex]
    );
    delete newFieldValue.entities.chatrooms[chatroomIdToDelete];

    newFieldValue.result = R.reject(
      R.equals(chatroomIdToDelete),
      R.clone(newFieldValue.result)
    );

    return { value: newFieldValue };
  } else {
    const newFieldValue = R.clone(oldValues[payload.id]);

    delete newFieldValue.entities.chatrooms[chatroomIdToDelete];

    newFieldValue.result = R.reject(
      R.equals(chatroomIdToDelete),
      R.clone(newFieldValue.result)
    );

    return { value: newFieldValue };
  }
};

/**
 * Filters out the user/group to be removed and returns the result
 * @param {Object} removedUserOrGroup - the user or group to be removed
 * @param {Object[]} oldValues - previous value of the user field
 * @return {Object[]} result after removing the user/group from the
 * list of users/groups
 */
function deleteUserOrGroup(removedUserOrGroup, oldValues) {
  const id = removedUserOrGroup?.type === "group" ? "id" : "uid";
  const result = oldValues.filter(member => {
    if (typeof removedUserOrGroup?.[id] === typeof member?.[id]) {
      return removedUserOrGroup?.[id] !== member?.[id];
    } else {
      return true;
    }
  });
  return result;
}

function* updateChecklistFromManageView({ payload }: Action): any {
  const autoNo = getChatRoom(
    yield select(getAppState),
    `${payload.roomId}`
  ).autoNo;
  const allInstances = (yield select(getAppState)).workflow;
  const oldValues = allInstances.instancesById[autoNo];

  const originalRoomId = payload.roomId;

  // Update the roomId for embedded fields
  if (payload.columnId && payload.columnId.includes("-")) {
    const linkedFieldId = R.init(payload.columnId.split("-")).join();
    const linkedFieldValues = oldValues[linkedFieldId];
    if (linkedFieldValues?.entities) {
      const embeddedFieldRoomId =
        linkedFieldValues.result[payload.embeddedIndex ?? payload.index];
      payload = {
        ...payload,
        roomId: embeddedFieldRoomId
      };
    }
  }

  try {
    let checklistValue = yield call(api.setChecklistFieldValue, payload);
    if (payload.value.type === "pdf") {
      toast.success("PDF is being generated now");
    } else {
      toast.success("Checklist updated");
    }

    let fieldValue;
    // Only used if DELETE operation is performed on user field
    let updatedUsersAndGroups = [];

    // Since we have a chain of sagas, introducing another action
    // seemed complex so a function is used instead to handle
    // DELETE requests for linked fields
    if (payload.value.type === "link") {
      if (payload.httpMethod === "DELETE") {
        fieldValue = linkedFieldDeleteHandler(oldValues, payload);
      } else if (payload.httpMethod === "PATCH") {
        fieldValue = formatChecklistFieldValue(checklistValue);
      }
    } else if (
      payload.value.type === "user" &&
      // $FlowFixMe
      !payload.columnId?.includes("-")
    ) {
      // If DELETE operation is performed, manually filter out the list
      // the API returns 204
      if (payload.httpMethod === "DELETE") {
        updatedUsersAndGroups = deleteUserOrGroup(
          payload.value.value,
          oldValues[payload.columnId]
        );
        updatedUsersAndGroups = Array.isArray(updatedUsersAndGroups[0])
          ? R.flatten(updatedUsersAndGroups)
          : updatedUsersAndGroups;
      }
    }

    const field = yield select(getChecklistFieldById(`${payload.id}`));
    const fieldType = field.get("type");

    let value;

    // For all embedded fields except for the embedded linked fields
    if (
      payload.columnId &&
      payload.columnId.includes("-") &&
      fieldType !== "link"
    ) {
      value = [...(oldValues[payload.columnId] || [])];
      if (fieldType === "user" || payload.value.type === "user") {
        updatedUsersAndGroups = payload.value.value;
        // If DELETE operation is performed, manually filter out the list
        // the API returns 204
        if (payload.httpMethod === "DELETE") {
          updatedUsersAndGroups = deleteUserOrGroup(
            payload.value.value,
            R.flatten(
              oldValues[payload.columnId][
                payload.embeddedIndex ?? payload.index
              ]
            )
          );
        }
        updatedUsersAndGroups = Array.isArray(updatedUsersAndGroups[0])
          ? R.flatten(updatedUsersAndGroups)
          : updatedUsersAndGroups;
      } else {
        value[payload.embeddedIndex ?? payload.index] = fieldValue
          ? fieldType === "select"
            ? [fieldValue.value]
            : fieldValue.value
          : fieldType === "select"
            ? [checklistValue.value]
            : checklistValue.value;
      }
    } else if (fieldType !== "user" && payload.httpMethod !== "DELETE") {
      value = fieldValue ? fieldValue.value : checklistValue.value;
    }

    if (fieldType === "link") {
      // Update the table when a linked conversation is added or removed
      try {
        const instances = allInstances.instancesById || {};

        if (fieldValue) {
          // For embedded linked fields
          // $FlowFixMe - Flow doesn't support optional chaining
          if (payload.columnId?.includes("-") && fieldValue) {
            const parentLinkedFieldId = R.init(
              `${payload.columnId}`.split("-")
            );

            // Original field ID
            const fieldId =
              R.last(`${payload.columnId}`.split("-")) || `${payload.fieldId}`;

            // The instance that is being updating
            const currentInstance = instances[autoNo || ""];

            const updatedFieldValue = Object.values(
              fieldValue.value.entities.chatrooms
            );

            // Checking if the field exists or needs to be created
            if (currentInstance[parentLinkedFieldId]) {
              const parentLinkedFieldValue: LinkedFieldValue =
                currentInstance[parentLinkedFieldId];

              // Check if the chatroom exists
              if (
                parentLinkedFieldValue.entities.chatrooms &&
                parentLinkedFieldValue.entities.chatrooms[payload.roomId]
                  .chatroom &&
                parentLinkedFieldValue.result.includes(parseInt(payload.roomId))
              ) {
                // Update the value inside the chatroom
                parentLinkedFieldValue.entities.chatrooms[
                  payload.roomId
                ].chatroom[fieldId] = updatedFieldValue;
              }
            }

            if (currentInstance[`${payload.columnId}`]) {
              currentInstance[`${payload.columnId}`][
                `${payload.embeddedIndex}`
              ] = fieldValue.value;
            } else {
              currentInstance[`${payload.columnId}`] = [fieldValue.value];
            }
          } else {
            instances[autoNo || ""][`${payload.id}`] = fieldValue.value;
          }
        }

        yield put({
          type: atypes.GET_WORKFLOW_INSTANCES_SUCCESS,
          payload: {
            workflows: Object.values(instances || {})
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else if (fieldType === "pdf") {
      while (true) {
        const action = yield take(atypes.NEW_MESSAGES);

        const fieldUpdateNotification = action.payload.messages.result.find(
          messageId => {
            const message = action.payload.messages.entities[messageId];

            return Boolean(
              // If the PDF is uploaded to a file field, look for that in notification
              R.path(
                ["data", "fields", R.last(payload.columnId.split("-"))],
                message
              )
            );
          }
        );

        if (fieldUpdateNotification) break;
      }
      yield put({
        type: atypes.UPDATE_CHECKLIST_FROM_MANAGE_VIEW_OPTIMISTIC,
        payload: {
          autoNo,
          values: {
            ...oldValues,

            [payload.columnId || payload.id]: value
          }
        }
      });
    } else {
      if (payload.columnId && payload.columnId.includes("-")) {
        const linkedFieldId = R.init(payload.columnId.split("-")).join();
        const fieldId = R.last(payload.columnId.split("-"));
        const filteredInstances = R.filter(
          instance =>
            R.includes(payload.roomId, instance[linkedFieldId]?.result || []),
          allInstances.instancesById
        );

        // To prevent null values from getting stored when DELETE
        // operation is perfromed
        const updatedValue = checklistValue
          ? checklistValue.value
          : fieldValue?.value;

        yield put({
          type: atypes.UPDATE_EMBEDDED_CHECKLIST_FROM_MANAGE_VIEW_OPTIMISTIC,
          payload: {
            roomId: payload.roomId,
            linkedFieldId,
            fieldId,
            value:
              payload.value.type === "user" && payload.httpMethod === "DELETE"
                ? updatedUsersAndGroups
                : updatedValue,
            filteredInstances: Object.values(filteredInstances),
            columnId: payload.columnId
          }
        });
      } else {
        let updatedValue = value;
        if (fieldType === "user") {
          updatedValue =
            payload.httpMethod === "DELETE"
              ? updatedUsersAndGroups
              : checklistValue?.value;
        }
        yield put({
          type: atypes.UPDATE_CHECKLIST_FROM_MANAGE_VIEW_OPTIMISTIC,
          payload: {
            autoNo,
            values: {
              ...oldValues,

              [payload.columnId || payload.id]: updatedValue
            }
          }
        });
      }
    }
    // Since form values are stored in checklist -> formValues
    // Prevent firing SET_CHECKLIST_VALUE_SUCCESS when setting a
    // form value
    if (payload.value.type === "form") {
      const {
        fields,
        fieldValues,
        fieldsByForm,
        formTemplates,
        embedded,
        nestedFieldDetails
      } = getFormData({ value: checklistValue, roomId: payload.roomId });

      yield put({
        type: atypes.GET_CHECKLIST_FIELD_VALUES_SUCCESS,
        payload: embedded
      });

      yield put({
        type: atypes.UPDATE_CHECKLIST_FIELDS,
        payload: nestedFieldDetails
      });

      yield put({
        type: atypes.GET_CHECKLIST_FIELDS_SUCCESS,
        payload: { fields }
      });

      yield put({
        type: atypes.GET_CHECKLIST_FORM_VALUES,
        payload: fieldValues
      });

      yield put({
        type: atypes.FETCH_FORM_TEMPLATES,
        payload: formTemplates
      });

      yield put({
        type: atypes.FETCH_FORM_FIELDS_SUCCESS,
        payload: {
          fields: fieldsByForm,
          roomId: payload.roomId,
          fieldId: payload.id
        }
      });

      const allFormFields = checklistValue.value.reduce((prev, curr) => {
        const res = {
          [curr.id]: curr.fields.map(f => f.fieldId)
        };
        return R.mergeDeepRight(prev, res);
      }, {});

      yield put({
        type: atypes.GET_FORM_FIELD_VALUES_SUCCESS,
        payload: allFormFields
      });
    }

    // Use value from API response instead of optimistic value from frontend
    // Use different data for DELETE operation on user fields beacuse API
    // doesn't return anything
    const updatedChecklistValue =
      fieldType === "user" && payload.httpMethod === "DELETE"
        ? updatedUsersAndGroups
        : fieldValue
          ? fieldValue.value
          : checklistValue.value;

    yield put({
      type: atypes.SET_SELECTED_CHECKLIST_FIELD_FROM_MANAGE_VIEW,
      payload: {
        index: payload.index,
        fieldId: payload.id,
        roomId:
          payload.roomId !== originalRoomId ? originalRoomId : payload.roomId,
        columnId: payload.columnId,
        formId: payload.formId,
        autoNo: payload.autoNo,
        embeddedIndex: payload.embeddedIndex,
        value: updatedChecklistValue
      }
    });
  } catch (error) {
    console.log(error);
    toast.error("Unable to update checklist");
    yield put({
      type: atypes.UPDATE_CHECKLIST_FROM_MANAGE_VIEW_FAILURE,
      payload: {
        index: payload.index,
        values: oldValues
      }
    });
  } finally {
    if (payload.value.type === "form") {
      yield put({
        type: atypes.HIDE_FORM_CREATION_LOADER,
        payload: {
          roomId: payload.roomId,
          fieldId: payload.id
        }
      });
    }
  }
}

function* watchUpdateChecklistFromManageView(): any {
  yield takeLatest(
    atypes.UPDATE_CHECKLIST_FROM_MANAGE_VIEW_REQUEST,
    updateChecklistFromManageView
  );
}

function* setManageViewFilter({ payload }: Action): any {
  try {
    const appState = yield select(getAppState);
    const workflow = appState.workflow;
    const filters = workflow.instanceFilter;
    const currentUserUid = appState.currentUser.uid;

    Object.keys(payload).map(columnId => {
      const targetIndex = R.findIndex(
        R.equals(ME_FILTER),
        payload[columnId] || []
      );
      if (targetIndex !== -1) {
        const updatedPayload = R.update(
          targetIndex,
          `me-${currentUserUid}`,
          payload[columnId] || []
        );
        payload[columnId] = updatedPayload;
      }
    });

    // Override existing filters
    yield put({
      type: atypes.SET_INSTANCE_FILTER,
      payload: {
        ...R.pick(["id", "reportId", "chartId"], filters),
        ...payload
      }
    });
  } catch (error) {
    console.log(error);
  }
}

function* watchSetManageViewFilter(): any {
  yield takeLatest(atypes.SET_MANAGE_VIEW_FILTER, setManageViewFilter);
}

function* paginateMageView({ payload }: Action): any {
  try {
    const filters = (yield select(getAppState)).workflow.instanceFilter;
    const { additionalFilters } = (yield select(getAppState)).workflow;

    if (filters.reportId) {
      yield put({
        type: atypes.SET_REPORTS_REQUEST,
        payload: {
          id: filters.reportId
        },
        meta: {
          query: { ...additionalFilters, ...payload }
        }
      });
    } else {
      yield put({
        type: atypes.SET_PROCESS_REQUEST,
        meta: {
          query: {
            ...filters,
            ...payload
          }
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchPaginateManageView(): any {
  yield takeLatest(atypes.PAGINATE_MANAGE_VIEW_FILTER, paginateMageView);
}

// Saga triggers when user saves from workflow editor
function* setWorkflowChanges(): any {
  const workflowBuilder = (yield select(getAppState)).workflow.builderDialog;
  const currentChecklist = (yield select(getAppState)).checklistBuilder.current;

  const newChecklist = R.omit(["title", "oldSettings", "id"], currentChecklist);

  if (R.isEmpty(workflowBuilder.title)) {
    toast.warn("Please give a title for the process");
    return;
  }

  const { edit } = workflowBuilder;

  const statuses = (workflowBuilder.status || []).map(item => {
    return R.mergeDeepRight(item, {
      rules: {
        blocks: convertV1ToV2(item?.rules?.blocks || []),
        defaultBehavior: item?.rules?.defaultBehavior
      }
    });
  });

  // All checklist fields have settings as an object, but they need to
  // be stringified before passing as API payload
  const allNewChecklistFields = R.map(
    field => {
      const settings = JSON.stringify(field.settings);

      // Remove leading or trailing white spaces from checklist name
      const label = (field.label || "").trim();

      return { ...field, settings, label };
    },
    R.concat(newChecklist.fields, newChecklist.deletedFields)
  );

  const formattedTitle = sanitizeTitle(workflowBuilder.title || "");

  if (edit) {
    yield put(
      workflowActions.editWorkflow({
        ...workflowBuilder,
        checklistFields: allNewChecklistFields,
        address: formattedTitle.toLowerCase(),
        status: statuses,
        draft: false
      })
    );
  } else {
    yield put(
      workflowActions.createWorkflow(
        {
          ...workflowBuilder,
          checklistFields: allNewChecklistFields,
          status: statuses,
          draft: false
        },
        true
      )
    );
  }
}

function* watchSetWorkflowChanges(): any {
  yield takeLatest(atypes.SET_WORKFLOW_CHANGES, setWorkflowChanges);
}

function* setWorkflowChangesAndRedirectToChatroom({ payload }: Action) {
  yield put({
    type: atypes.SET_WORKFLOW_CHANGES
  });

  const updateResult = yield race({
    success: take(atypes.EDIT_WORKFLOW_SUCCESS),
    failure: take(atypes.EDIT_WORKFLOW_FAILURE)
  });

  if (updateResult.success) {
    yield put({
      type: atypes.SET_CURRENT_CHATROOM_REQUEST,
      payload: {
        id: payload.address
      },
      meta: {
        query: {
          templateId: payload.templateId
        }
      }
    });
  }
}

function* watchSetWorkflowChangesAndRedirectToChatroom(): any {
  yield takeEvery(
    atypes.SET_WORKFLOW_CHANGES_AND_REDIRECT_TO_CHATROOM,
    setWorkflowChangesAndRedirectToChatroom
  );
}

// Saga triggers when user cancels from workflow editor
function* cancelWorkflowChanges(): any {
  yield put(showView("manage"));
  yield put(workflowActions.setWorkflow({}));
}

function* watchCancelWorkflowChanges(): any {
  yield takeLatest(atypes.CANCEL_WORKFLOW_CHANGES, cancelWorkflowChanges);
}

// Saga triggers when user saves from checklist field settings
function* setFieldSettingsChanges(): any {
  yield put(
    workflowActions.setWorkflowBuiderAttributes({
      checklistFieldSettings: {}
    })
  );
}

// Saga triggers when user cancels from checklist field settings
function* cancelFieldSettingsChanges(): any {
  const currentChecklist = (yield select(getAppState)).checklistBuilder.current;
  const checklistFieldSettings = (yield select(getAppState)).workflow
    .builderDialog.checklistFieldSettings;

  // Close checklist settings page
  yield put(
    workflowActions.setWorkflowBuiderAttributes({
      checklistFieldSettings: {}
    })
  );

  // Restore old prompts info
  const oldSettings = currentChecklist.oldSettings;

  const newFields = R.adjust(
    checklistFieldSettings.position,
    data => ({ ...data, ...oldSettings }),
    currentChecklist.fields
  );

  yield put(
    setChecklistBuilderAttributes({
      value: {
        fields: newFields,
        oldSettings: {}
      }
    })
  );
}

function* watchSetFieldSettingsChanges(): any {
  yield takeLatest(
    atypes.SET_CHECKLIST_FIELD_SETTINGS_CHANGES,
    setFieldSettingsChanges
  );
}

function* watchCancelFieldSettingsChanges(): any {
  yield takeLatest(
    atypes.CANCEL_CHECKLIST_FIELD_SETTINGS_CHANGES,
    cancelFieldSettingsChanges
  );
}

/**
 * Flatten all the nested checklist field data
 * For example, let's take a conversation with embedded fields:
 * { ..., 2525: [{ title: "", seqNo: 2, ..., 2425: "Hey" }] }
 *
 * To
 *
 * {
 *   ...,
 *   2525: [{ title: "", seqNo: 2, ..., 2425: "Hey" }],
 *   2525-2425: "Hey"
 * }
 */

const getMergedInstance = (instances: Array<Object>) => {
  const mergedInstance = {};

  const allFields = instances.reduce(
    (acc, instance) => R.union(acc, Object.keys(instance)),
    []
  );

  [...instances].forEach(instance => {
    Object.keys(instance).forEach(key => {
      const isLinkedEmbeddedField =
        R.type(instance[key]) === "Object" &&
        instance[key].result &&
        instance[key].entities?.chatrooms;

      if (!mergedInstance[key]) {
        if (key.endsWith("-meta")) {
          mergedInstance[key] = instance[key];
        } else {
          mergedInstance[key] = [instance[key]];
        }
      } else {
        if (Array.isArray(instance[key]) && key.endsWith("-meta")) {
          mergedInstance[key] = mergedInstance[key].concat(instance[key]);
        } else {
          if (isLinkedEmbeddedField) {
            mergedInstance[key] = [...mergedInstance[key], instance[key]];
          } else {
            if (Array.isArray(instance[key])) {
              mergedInstance[key] = [...mergedInstance[key], instance[key]];
            } else {
              mergedInstance[key] = mergedInstance[key].concat(instance[key]);
            }
          }
        }
      }
    });
    R.difference(allFields, Object.keys(instance)).forEach(key => {
      mergedInstance[key] = mergedInstance[key]
        ? mergedInstance[key].concat(null)
        : [null];
    });
  });
  return [mergedInstance];
};

const flattenInstances = ({
  instances,
  checklistFields,
  parentFieldId
}: {
  instances: Array<Object>,
  checklistFields: FieldsById,
  parentFieldId?: string
}) => {
  const flattenedInstances = instances.map(workflow => {
    let flattenedInstance = parentFieldId ? {} : R.clone(workflow);
    const workflowFields = R.keys(workflow).filter(key => parseInt(key));

    let maxRows = 0;

    workflowFields.forEach(field => {
      const isConversationField = R.type(workflow[field]?.[0]) === "Object";
      const isEmbeddedLinkedField =
        R.type(workflow[field]?.[0]) === "Object" &&
        workflow[field][0]?.chatroom;
      const isLinkedField =
        R.type(workflow[field]) === "Object" &&
        workflow[field].result &&
        workflow[field].entities?.chatrooms;

      // $FlowFixMe
      const isFormField = checklistFields.get(field)?.get("type") === "form";

      // Value is structured differently for linked fields and embedded
      // linked fields, so make it consistent with linked field for
      // embedded linked fields too
      const fieldValue = isEmbeddedLinkedField
        ? formatManageViewFieldValue(workflow[field], "link")
        : workflow[field];

      const allConversations = (() => {
        // If it's a linked field, get all the chatrooms in an
        // array
        if (isLinkedField) {
          return workflow[field].result.map(
            roomId => workflow[field].entities.chatrooms[roomId]?.chatroom || {}
          );
        } else if (isEmbeddedLinkedField) {
          return workflow[field].map(value => value.chatroom);
        }

        return workflow[field];
      })();

      if (
        isConversationField ||
        isEmbeddedLinkedField ||
        isLinkedField ||
        isFormField
      ) {
        const mergeInstance = getMergedInstance(
          flattenInstances({
            instances: allConversations,
            checklistFields,
            parentFieldId: parentFieldId ? `${parentFieldId}-${field}` : field
          })
        );

        flattenedInstance = {
          ...flattenedInstance,
          ...(mergeInstance[0] || {})
        };
      }
      if (parentFieldId) {
        flattenedInstance[`${parentFieldId}-${field}`] = fieldValue;
      }

      if (field.endsWith("-meta")) {
        maxRows = Math.max(maxRows, workflow[field].length);
        flattenedInstance = {
          ...flattenedInstance,
          maxRows
        };
      }
    });

    return flattenedInstance;
  });

  return flattenedInstances;
};

function* flattenWorkflowIntances({ payload }: Action): any {
  try {
    const checklistFields = yield select(getChecklistFieldsById);
    const flattenedInstances = flattenInstances({
      instances: payload.workflows,
      checklistFields
    });

    yield put({
      type: atypes.SET_UPDATED_INSTANCES,
      payload: { workflows: flattenedInstances }
    });
  } catch (e) {
    console.error("Failed to flatten form field data", e);
  }
}

function* watchGetWorkflowInstancesSuccess(): any {
  yield takeEvery(
    atypes.GET_WORKFLOW_INSTANCES_SUCCESS,
    flattenWorkflowIntances
  );
}

/**
 * On expanding the value of a form field or conversation field, add
 * the values as rows in the manage view
 */
function* setExpandedField(): any {
  const { expandedFields } = (yield select(getAppState)).workflow;
  const { instancesById } = (yield select(getAppState)).workflow;
  const expandedRows = R.keys(expandedFields);

  // All instances without expansion
  let updatedInstancesById = R.clone(
    R.omit(
      Object.keys(instancesById).filter(autoNo => {
        return R.includes("-", autoNo);
      }),
      instancesById
    )
  );

  for (let expandedRow of expandedRows) {
    const expandedFieldIds = R.keys(expandedFields[expandedRow]).filter(
      fieldId => expandedFields[expandedRow][fieldId]
    );

    for (let expandedFieldId of expandedFieldIds) {
      const instance = updatedInstancesById[expandedRow];

      if (instance) {
        for (let [index, formValue] of (instance[expandedFieldId] || [])
          .slice(1)
          .entries()) {
          const fieldsOfForm = R.keys(formValue).filter(key => parseInt(key));

          // Update/create a row for expanded form
          updatedInstancesById[`${expandedRow}-${index + 1}`] = {
            ...(updatedInstancesById[`${expandedRow}-${index + 1}`] || {}),
            [expandedFieldId]: [instance[expandedFieldId][index + 1]],
            currentVersion: true,
            title: null,
            chatroomId: instance.chatroomId,
            members: instance.members,
            processMembers: instance.processMembers,
            privacy: instance.privacy,
            isExpandedSubrow: true
          };
          for (let fieldId of fieldsOfForm) {
            // Set value for form fields in the expanded rows
            updatedInstancesById[`${expandedRow}-${index + 1}`] = {
              ...(updatedInstancesById[`${expandedRow}-${index + 1}`] || {}),
              currentVersion: true,
              title: null,
              chatroomId: instance.chatroomId,
              isExpandedSubrow: true,
              [`${expandedFieldId}-${fieldId}`]:
                instance[expandedFieldId][index + 1][fieldId]
            };
          }
        }
      }
    }
  }

  yield put({
    type: atypes.SET_UPDATED_INSTANCES_BY_ID,
    payload: updatedInstancesById
  });
}

function* watchSetExpandedField(): any {
  yield takeEvery(atypes.SET_EXPANDED_FIELD, setExpandedField);
}

function* getProcessFieldMappings({ payload }: Action): any {
  try {
    const { templateId, fileName } = payload;
    const mappedFields = yield call(workflow.getProcessFieldMappings, {
      templateId,
      fileName
    });

    const sortedKeys = Object.keys(mappedFields);
    sortedKeys.sort(
      (cur, next) => mappedFields[cur].seqNo - mappedFields[next].seqNo
    );
    let sortedFields = {};
    for (let key of sortedKeys) {
      sortedFields[key] = mappedFields[key];
    }

    yield put({
      type: atypes.GET_PROCESS_FIELD_MAPPINGS_SUCCESS,
      payload: { templateId, mappedFields: sortedFields }
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: atypes.GET_PROCESS_FIELD_MAPPINGS_FAILURE,
      payload: { error: e, templateId: payload.templateId }
    });
    toast.error("Failed to get field mappings");
  }
}

function* watchProcessFieldMappings(): any {
  yield takeEvery(
    atypes.GET_PROCESS_FIELD_MAPPINGS_REQUEST,
    getProcessFieldMappings
  );
}

function* storeProcessFieldMappings({ payload }: Action): any {
  try {
    const { templateId, fileName, localMappedField, file } = payload;
    yield call(workflow.storeProcessFieldMappings, {
      templateId,
      fileName,
      localMappedField,
      file
    });

    yield put({
      type: atypes.STORE_PROCESS_FIELD_MAPPINGS_SUCCESS,
      payload: { templateId }
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: atypes.STORE_PROCESS_FIELD_MAPPINGS_FAILURE,
      payload: { error: e, templateId: payload.templateId }
    });
  }
}

function* watchStoreProcessFieldMappings(): any {
  yield takeEvery(
    atypes.STORE_PROCESS_FIELD_MAPPINGS_REQUEST,
    storeProcessFieldMappings
  );
}

function* editCurrentLinkedField({ payload }): any {
  try {
    // This will only run when updating multi linked field values
    // from manage view in bulk update mode
    const { value } = payload;

    const selectedChecklist = yield select(getSelectedField);
    let updatedFieldValue = R.clone(selectedChecklist.value);

    if (payload.httpMethod === httpMethods.delete) {
      // Delete a single chatroom from linked field
      const chatroomIdToDelete = value.value.originChatroomId;
      delete updatedFieldValue.entities.chatrooms[chatroomIdToDelete];
      updatedFieldValue.result = R.reject(
        R.equals(chatroomIdToDelete),
        R.clone(updatedFieldValue.result)
      );
    } else {
      // Add a chatroom to the source linked field but only update the
      // app.checklist.selectedChecklist state and don't make the API
      // request
      updatedFieldValue = getCurrentLinkedFieldValue(
        yield select(getAppState),
        payload.value,
        "link",
        payload.httpMethod
      );
    }

    yield put({
      type: atypes.UPDATE_CURRENT_FIELD_SUCCESS,
      payload: {
        value: updatedFieldValue
      }
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: atypes.UPDATE_CURRENT_FIELD_FAILURE,
      payload: { error: err }
    });
  }
}

function* watchCurrentLinkedFieldUpdates(): any {
  yield takeEvery(atypes.UPDATE_CURRENT_FIELD_REQUEST, editCurrentLinkedField);
}

export default [
  watchPaginateManageView(),
  watchSetManageViewFilter(),
  watchUpdatedNestedChatroomManageView(),
  watchUpdateChatroomFromManageView(),
  watchGetSystemProcess(),
  watchSearchPrincipalChecklist(),
  watchViewProcessDetails(),
  watchFetchStatusCount(),
  watchMostUsedWorkflow(),
  watchSetProcessAlias(),
  watchSyncCustomView(),
  watchSaveCustomView(),
  watchBulkUpdate(),
  watchCreateWorkflow(),
  watchSyncWorkflows(),
  watchSrwSyncWorkflows(),
  watchLoadWorkflow(),
  watchEditWorkflow(),
  WatchDeleteWorkflow(),
  watchGetWorkflowInstanes(),
  watchGetNextSeqNo(),
  watchSetRecentWorkflow(),
  watchSearchWorkflow(),
  watchResetWorkflowSearch(),
  watchShowProcess(),
  watchUniqueProcessInstanceValues(),
  watchDownloadProcessInstance(),
  watchRefetchWorkflowInstances(),
  watchGetPrincipalChecklist(),
  watchUpdateChecklistFromManageView(),
  watchUpdateWorkflowInstanceCurrentVersion(),
  watchSetWorkflowChanges(),
  watchCancelWorkflowChanges(),
  watchSetFieldSettingsChanges(),
  watchCancelFieldSettingsChanges(),
  watchSetWorkflowChangesAndRedirectToChatroom(),
  watchGetWorkflowInstancesSuccess(),
  watchSetExpandedField(),
  watchGetFieldUniqueValues(),
  watchProcessFieldMappings(),
  watchStoreProcessFieldMappings(),
  watchCurrentLinkedFieldUpdates()
];
